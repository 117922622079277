<template>
  <div class="apply-form__section apply-form__documents">
    <h3>{{ $t('application.documents') }}</h3>
    <div :v-html="$t('application.documentsInfo')"></div>

    <ValidationObserver>
      <v-row
        v-if="
          documentsSettings.letterValidationMode !==
          fieldValidationMode.NotPrompted
        "
        class="mt-4 border-bot"
      >
        <v-col cols="12" md="6" class="d-flex align-center">
          <v-radio-group v-model="letterMode" hide-details="true" class="mt-0">
            <v-radio
              value="upload"
              :label="$t('application.letterUpload')"
            ></v-radio>
            <v-radio
              value="text"
              :label="$t('application.letterAsText')"
            ></v-radio>
          </v-radio-group>
        </v-col>

        <v-col cols="12" md="6" class="d-flex align-center">
          <template v-if="letterMode === 'upload'">
            <ValidationProvider
              v-slot="validationData"
              name="letter_file"
              mode="eager"
              slim
              :rules="
                getLetterValidationRules(
                  documentsSettings.letterValidationMode,
                  'upload'
                )
              "
            >
              <file-input
                v-model="application.letter"
                :label="
                  getLabel(
                    'application.letter',
                    documentsSettings.letterValidationMode
                  )
                "
                :accept="allowedFileTypes"
                :upload-endpoint="'application-file'"
                :download-endpoint="'application-file'"
                :additional-values="{ fileType: fileType.Letter }"
                :file-prefix="formId"
                :validation-data="validationData"
                :name="'letter-file'"
              ></file-input>
            </ValidationProvider>
          </template>

          <template v-else-if="letterMode === 'text'">
            <ValidationProvider
              v-slot="{ errors }"
              name="letter_file"
              mode="eager"
              slim
              :rules="
                getLetterValidationRules(
                  documentsSettings.letterValidationMode,
                  'text'
                )
              "
            >
              <v-textarea
                v-model="application.letterText"
                hide-details="auto"
                solo
                flat
                :label="
                  getLabel(
                    'application.letter',
                    documentsSettings.letterValidationMode
                  )
                "
                :error-messages="errors"
              ></v-textarea>
            </ValidationProvider>
          </template>
        </v-col>
      </v-row>

      <v-row class="mt-md-0">
        <v-col
          v-if="
            documentsSettings.cvValidationMode !==
            fieldValidationMode.NotPrompted
          "
          cols="12"
          md="6"
        >
          <ValidationProvider
            v-slot="validationData"
            name="cv_file"
            mode="eager"
            slim
            :rules="getValidationRules(documentsSettings.cvValidationMode)"
          >
            <file-input
              v-model="application.cv"
              :label="
                getLabel('application.cv', documentsSettings.cvValidationMode)
              "
              :accept="allowedFileTypes"
              :upload-endpoint="'application-file'"
              :download-endpoint="'application-file'"
              :additional-values="{ fileType: fileType.CV }"
              :file-prefix="formId"
              :validation-data="validationData"
              @fileUploaded="fileUploadedHandler"
            ></file-input>
          </ValidationProvider>
        </v-col>

        <v-col
          v-if="
            documentsSettings.documentsValidationMode !==
            fieldValidationMode.NotPrompted
          "
          cols="12"
          md="6"
        >
          <ValidationProvider
            v-slot="validationData"
            name="documents_file"
            mode="eager"
            slim
            :rules="
              getValidationRules(documentsSettings.documentsValidationMode)
            "
          >
            <file-input
              v-model="application.documents"
              multiple
              :label="
                getLabel(
                  'application.additionalDocuments',
                  documentsSettings.documentsValidationMode
                )
              "
              :accept="allowedFileTypes"
              :upload-endpoint="'application-file'"
              :download-endpoint="'application-file'"
              :additional-values="{ fileType: fileType.Document }"
              :file-prefix="formId"
              :validation-data="validationData"
            ></file-input>
          </ValidationProvider>
        </v-col>
      </v-row>
    </ValidationObserver>

    <div class="mt-4">
      <i class="text-sm text--muted">{{ $t('application.fileSizeInfo') }}</i>
    </div>

    <cv-parser-dialog v-model="parsing"></cv-parser-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import { eventBus } from '@/event-bus.js';

import cvParserDialog from './cv-parser-dialog.vue';

import fileInput from '../file-input.vue';
import fileTypeEnums from '@/enum/application-file-type.js';
import validationModeEnums from '@/enum/field-validation-mode.js';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    documentsSettings: {
      type: Object,
      required: true
    },

    jobofferId: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    allowedFileTypes: 'image/*, .pdf, .docx, .doc',
    uploading: false,
    parsing: false,
    letterMode: 'upload'
  }),

  computed: {
    ...mapState('applicationModule', {
      formId: 'formId'
    }),

    application: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },

    cvFile() {
      return this.application.cv;
    },

    fieldValidationMode() {
      return validationModeEnums.FieldValidationModes;
    },

    fileNotEmptyRule() {
      return [
        (v) => !!v || this.$t('application.fieldIsMandatory'),
        (v) => (v && v.length > 0) || this.$t('application.fieldIsMandatory')
      ];
    },

    fileType() {
      return fileTypeEnums.ApplicationFileTypes;
    }
  },

  methods: {
    ...mapActions('applicationModule', {
      uploadApplicationFile: 'uploadFile',
      parseCvFile: 'parseCvFile'
    }),

    async fileUploadedHandler() {
      if (this.cvFile.length > 0) {
        this.parsing = true;

        const cvDataRequest = {
          application: this.application,
          objectId: this.cvFile[0].objectName,
          formId: this.formId,
          jobofferId: this.jobofferId
        };

        let result = null;
        try {
          result = await this.parseCvFile(cvDataRequest);
          this.$emit('file-parsed', result);
        } catch {
          eventBus.$emit(
            'errorMessage',
            'Es ist ein Fehler beim Auslesen des Lebenslaufs aufgetreten'
          );
        }

        this.parsing = false;
      }
    },

    getLabel(labelKey, validationMode) {
      let label = this.$t(labelKey);
      if (validationMode === this.fieldValidationMode.Mandatory) {
        label += '*';
      }

      return label;
    },

    getLetterValidationRules(validationMode, inputType) {
      if (
        validationMode === this.fieldValidationMode.Mandatory &&
        inputType === this.letterMode
      ) {
        return 'required|fileUploaded';
      }

      return '';
    },

    getValidationRules(validationMode) {
      if (validationMode === this.fieldValidationMode.Mandatory) {
        return 'required|fileUploaded';
      }

      return '';
    },

    async uploadFile(file, fileType) {
      this.uploading = true;
      await this.uploadApplicationFile({
        file,
        fileType
      });

      this.uploading = false;
    }
  },

  components: {
    cvParserDialog,
    fileInput
  }
};
</script>
