var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-box",class:[
    _vm.colorClass,
    _vm.hasImageClass,
    _vm.hasCenteredClass,
    _vm.hasDenseClass,
    _vm.hasNoMarginClass,
    _vm.data.customClass
  ]},[_c('div',{staticClass:"content-box__title"},[(_vm.title !== '')?_c('h2',{domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e()]),_c('div',{staticClass:"content-box__content"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":_vm.text ? _vm.videoColumnWidth : 12}},[(_vm.data.videoType === _vm.contentEnums.videoTypes.SelfHosted)?[_c('video',{staticStyle:{"width":"100%"},attrs:{"src":_vm.data.videoUrl,"controls":"on"}})]:_vm._e()],2),(_vm.text)?_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","md":_vm.contentColumnWidth}},[_c('div',{staticClass:"d-flex flex-column",domProps:{"innerHTML":_vm._s(_vm.text)}})]):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }