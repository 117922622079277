<template>
  <v-row v-if="basicsItems.length > 0" class="border-bot">
    <v-col
      v-for="(basicsItem, index) in basicsItems"
      :key="`basics-form-${index}`"
      cols="12"
      :md="shortApply ? 12 : 6"
    >
      <basic-data-field
        v-model="basicsItems[index].value"
        :basic-data-item="basicsItem"
      ></basic-data-field>
    </v-col>
  </v-row>
</template>

<script>
import basicDataField from './basic-data-field.vue';

export default {
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    },

    groupIndex: {
      type: Number,
      required: true
    },

    shortApply: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    basicsItems: {
      get() {
        return this.value.filter((x) => x.groupIndex === this.groupIndex);
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },

  components: {
    basicDataField
  }
};
</script>
