<template>
  <div
    class="content-box"
    :class="[
      colorClass,
      hasImageClass,
      hasCenteredClass,
      hasDenseClass,
      hasNoMarginClass,
      data.customClass
    ]"
  >
    <div class="content-box__title">
      <h2 v-if="title !== ''" v-html="title"></h2>
    </div>

    <div class="content-box__content">
      <v-row>
        <v-col cols="12" :md="text ? videoColumnWidth : 12">
          <template
            v-if="data.videoType === contentEnums.videoTypes.SelfHosted"
          >
            <video
              :src="data.videoUrl"
              controls="on"
              style="width: 100%"
            ></video>
          </template>
        </v-col>
        <v-col
          v-if="text"
          cols="12"
          :md="contentColumnWidth"
          class="d-flex justify-center"
        >
          <div class="d-flex flex-column" v-html="text"></div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ContentEnums } from '@/enum/content.js';

export default {
  name: 'text-box-content',
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState('companyModule', {
      slug: 'slug'
    }),

    contentColumnWidth() {
      return 12 - this.data.imageColumnWidth;
    },

    contentEnums() {
      return ContentEnums;
    },

    colorClass() {
      if (this.data.variant === 1) {
        return 'content-box__color-primary';
      } else if (this.data.variant === 2) {
        return 'content-box__color-secondary';
      } else {
        return '';
      }
    },

    hasImageClass() {
      if (this.data.variant > 0) {
        return 'content-box__has-image';
      } else {
        return '';
      }
    },

    hasCenteredClass() {
      if (this.data.isCentered) {
        return 'text-center';
      } else {
        return '';
      }
    },

    hasDenseClass() {
      if (this.data.isDense) {
        return 'content-box__dense';
      } else {
        return '';
      }
    },

    hasNoMarginClass() {
      if (this.data.hasNoMargin) {
        return 'content-box__no-margin';
      } else {
        return '';
      }
    },

    imageUrl() {
      return `${process.env.VUE_APP_API_URL}/file/public?slug=${this.slug}&fileName=${this.data.imageName}`;
    },

    text() {
      if (!this.data.text || this.data.text.length === 0) {
        return '';
      }

      return this.localize(this.data.text);
    },

    title() {
      if (!this.data.title || this.data.title.length === 0) {
        return '';
      }

      return this.localize(this.data.title);
    },

    videoColumnWidth() {
      return this.data.imageColumnWidth;
    }
  }
};
</script>
