export default {
  slug: null,
  departments: [],
  departmentsLoaded: false,
  locations: [],
  locationsLoaded: false,
  filters: ['locations', 'career-levels', 'departments'],
  benefits: [],
  imprint: [],
  jobportalOnly: true,
  showExternalAdvert: false,
  isInternal: false,
  jobDataFields: [],
  pageTitle: [],
  languages: {},
  jobsDefaultTopContent: [],
  jobsDefaultBottomContent: [],
  footerContent: null,
  showAvailableFiltersOnly: true,
  customDataPrivacyUrl: null,
  customImprintUrl: null,
  defaultClaim: null,
  homeSearchField: false,
  homeJobsButton: false,
  jobsContentPosition: 'bottom',
  jobsDefaultContentPosition: 'bottom',
  heroVideoUrl: '',
  baseUrl: '',
  jobsLayout: 'list',
  jobsLayoutToggle: false
};
