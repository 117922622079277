export default Object.freeze({
  ApplicationBasicsFields: {
    Salutation: 1,
    Title: 2,
    FirstName: 3,
    LastName: 4,
    Birthdate: 5,
    Nation: 6,
    MaritalStatus: 7,
    ChildenUnder18: 8,
    AddressStreet: 9,
    AddressZip: 10,
    AddressCity: 11,
    AddressCountry: 12,
    Phone: 13,
    MobilePhone: 14,
    Fax: 15,
    Xing: 17,
    Skype: 18,
    RequestedSalary: 19,
    NoticePeriod: 20,
    PossibleStartDate: 21,
    Graduation: 22,
    CriminalRecord: 23,
    Schufa: 24,
    CarAndDriversLicense: 25,
    Disability: 26,
    LinkedIn: 27,
    AddressState: 28,
    Skills: 29,
    Interests: 30,
    UndatedSkills: 31,
    UndatedOperationAreas: 32,
    EmployeeNumber: 33,
    Location: 34,
    HighestDegree: 35,
    SecondaryJob: 36,
    HealthInsurance: 37,
    PrivateHealthInsurance: 38,
    BankAccountInfo: 39,
    TaxID: 40,
    PensionID: 41,
    Department: 42,
    OperationArea: 43,
    EntryDate: 44,
    Occupation: 45,
    ContractType: 46,
    Group: 47,
    BonusType: 48,
    Bonus: 49,
    HoursPerWeek: 50,
    Probation: 51,
    Temporary: 52,
    TemporaryDate: 53,
    TemporaryReason: 54,
    TerminationPeriod: 55,
    AdditionalAgreements: 56,
    DaysPerWeek: 57,
    OrganisationalDivision: 58,
    EmploymentType: 59,
    DifferentWorkplace: 60,
    Workplace: 61,
    TimeRecording: 62,
    Kostenstellen: 63,
    KostenstellenPercentage: 64,
    CouncilHearingDeadline: 66,
    CouncilComment: 67,
    Pension: 68,
    InternalApplication: 69,
    Birthname: 583,
    Birthplace: 584,
    PreviousJobs: 581,
    DistanceToWorkplace: 582
  }
});
