import jobsService from '@/services/JobsService.js';
import jobalertService from '@/services/JobalertService.js';
import { eventBus } from '@/event-bus';

export default {
  clearFilters({ state, commit }, filterType) {
    if (!filterType) {
      state.filters = {};
      eventBus.$emit('filtersCleared');
    } else {
      if (state.filters[filterType]) {
        state.filters[filterType] = [];
        eventBus.$emit('filtersCleared', filterType);
      }
    }

    commit('setContent', []);
  },

  async confirmJobalert(context, { jobalertId, jobalertToken }) {
    return await jobalertService.confirm(jobalertId, jobalertToken);
  },

  async loadJobDetails(context, jobData) {
    const response = await jobsService.getDetails(
      jobData.slug,
      jobData.jobofferId
    );
    const jobDetails = await response.json();

    return jobDetails;
  },

  async loadJobs({ state }, slug) {
    if (state.joboffers && state.joboffers.length > 0) {
      return state.joboffers;
    }

    const joboffersResponse = await jobsService.getJoboffers(slug);
    const joboffers = await joboffersResponse.json();

    state.joboffers = joboffers;
    return joboffers;
  },

  async loadInternalJobs({ state }, { slug, internalToken }) {
    if (state.joboffers && state.joboffers.length > 0) {
      return state.joboffers;
    }

    const joboffersResponse = await jobsService.getInternalJoboffers(
      slug,
      internalToken
    );

    const joboffers = await joboffersResponse.json();

    state.joboffers = joboffers;
    return joboffers;
  },

  setFilter({ state, rootState, commit }, filterData) {
    if (!state.filters) {
      state.filters = {};
    }

    const filtersCopy = JSON.parse(JSON.stringify(state.filters));
    if (filtersCopy[filterData.field]) {
      filtersCopy[filterData.field].push(filterData);
    } else {
      filtersCopy[filterData.field] = [filterData];
    }

    state.filters = filtersCopy;

    if (filterData.field === 'departmentId') {
      const allDepartments = rootState.companyModule.departments;
      const selectedDepartment = allDepartments.find(
        (x) => x.id === filterData.value
      );

      if (selectedDepartment) {
        commit('setContent', selectedDepartment.content);

        if (selectedDepartment.heroImageName) {
          commit('setHeroImage', selectedDepartment.heroImageName, {
            root: true
          });
        }
      }
    }

    if (filterData.field === 'locationId') {
      const allLocations = rootState.companyModule.locations;
      const selectedLocation = allLocations.find(
        (x) => x.id === filterData.value
      );

      if (selectedLocation) {
        commit('setContent', selectedLocation.content);

        if (selectedLocation.heroImageName) {
          commit('setHeroImage', selectedLocation.heroImageName, {
            root: true
          });

          commit('setHeroClaim', selectedLocation.heroClaim, {
            root: true
          });
        }
      }
    }
  },

  async unsubscribeJobalert(context, { jobalertId, jobalertToken }) {
    return await jobalertService.unsubscribe(jobalertId, jobalertToken);
  }
};
