<template>
  <div class="image-grid">
    <div
      v-for="(item, index) in items"
      :key="`image-grid_item_${index}`"
      class="image-grid__item"
      :class="`width-${item.width}`"
    >
      <div class="image-grid__item__label">
        {{ localize(item.label) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        width: '1/3',
        label: [{ languageKey: 'de-DE', translation: 'Kachel 1' }]
      },
      {
        width: '1/3',
        label: [{ languageKey: 'de-DE', translation: 'Kachel 2' }]
      },
      {
        width: '1/3',
        label: [{ languageKey: 'de-DE', translation: 'Kachel 3' }]
      },
      {
        width: '1/2',
        label: [{ languageKey: 'de-DE', translation: 'Kachel 4' }]
      },
      {
        width: '1/2',
        label: [{ languageKey: 'de-DE', translation: 'Kachel 5' }]
      },
      {
        width: '3/3',
        label: [{ languageKey: 'de-DE', translation: 'Kachel 6' }]
      }
    ]
  })
};
</script>

<style scoped lang="scss">
.image-grid {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: repeat(9, 5vw);
  grid-gap: 20px;
}
</style>
