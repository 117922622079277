import { render, staticRenderFns } from "./image-swiper.vue?vue&type=template&id=a8cd6416&scoped=true"
import script from "./image-swiper.vue?vue&type=script&lang=js"
export * from "./image-swiper.vue?vue&type=script&lang=js"
import style0 from "./image-swiper.vue?vue&type=style&index=0&id=a8cd6416&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8cd6416",
  null
  
)

export default component.exports