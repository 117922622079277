var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"apply-form__section apply-form__documents"},[_c('h3',[_vm._v(_vm._s(_vm.$t('application.documents')))]),_c('div',{attrs:{"v-html":_vm.$t('application.documentsInfo')}}),_c('ValidationObserver',[(
        _vm.documentsSettings.letterValidationMode !==
        _vm.fieldValidationMode.NotPrompted
      )?_c('v-row',{staticClass:"mt-4 border-bot"},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","md":"6"}},[_c('v-radio-group',{staticClass:"mt-0",attrs:{"hide-details":"true"},model:{value:(_vm.letterMode),callback:function ($$v) {_vm.letterMode=$$v},expression:"letterMode"}},[_c('v-radio',{attrs:{"value":"upload","label":_vm.$t('application.letterUpload')}}),_c('v-radio',{attrs:{"value":"text","label":_vm.$t('application.letterAsText')}})],1)],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","md":"6"}},[(_vm.letterMode === 'upload')?[_c('ValidationProvider',{attrs:{"name":"letter_file","mode":"eager","slim":"","rules":_vm.getLetterValidationRules(
                _vm.documentsSettings.letterValidationMode,
                'upload'
              )},scopedSlots:_vm._u([{key:"default",fn:function(validationData){return [_c('file-input',{attrs:{"label":_vm.getLabel(
                  'application.letter',
                  _vm.documentsSettings.letterValidationMode
                ),"accept":_vm.allowedFileTypes,"upload-endpoint":'application-file',"download-endpoint":'application-file',"additional-values":{ fileType: _vm.fileType.Letter },"file-prefix":_vm.formId,"validation-data":validationData,"name":'letter-file'},model:{value:(_vm.application.letter),callback:function ($$v) {_vm.$set(_vm.application, "letter", $$v)},expression:"application.letter"}})]}}],null,false,3825674790)})]:(_vm.letterMode === 'text')?[_c('ValidationProvider',{attrs:{"name":"letter_file","mode":"eager","slim":"","rules":_vm.getLetterValidationRules(
                _vm.documentsSettings.letterValidationMode,
                'text'
              )},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-textarea',{attrs:{"hide-details":"auto","solo":"","flat":"","label":_vm.getLabel(
                  'application.letter',
                  _vm.documentsSettings.letterValidationMode
                ),"error-messages":errors},model:{value:(_vm.application.letterText),callback:function ($$v) {_vm.$set(_vm.application, "letterText", $$v)},expression:"application.letterText"}})]}}],null,false,3180129228)})]:_vm._e()],2)],1):_vm._e(),_c('v-row',{staticClass:"mt-md-0"},[(
          _vm.documentsSettings.cvValidationMode !==
          _vm.fieldValidationMode.NotPrompted
        )?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"cv_file","mode":"eager","slim":"","rules":_vm.getValidationRules(_vm.documentsSettings.cvValidationMode)},scopedSlots:_vm._u([{key:"default",fn:function(validationData){return [_c('file-input',{attrs:{"label":_vm.getLabel('application.cv', _vm.documentsSettings.cvValidationMode),"accept":_vm.allowedFileTypes,"upload-endpoint":'application-file',"download-endpoint":'application-file',"additional-values":{ fileType: _vm.fileType.CV },"file-prefix":_vm.formId,"validation-data":validationData},on:{"fileUploaded":_vm.fileUploadedHandler},model:{value:(_vm.application.cv),callback:function ($$v) {_vm.$set(_vm.application, "cv", $$v)},expression:"application.cv"}})]}}],null,false,3977044731)})],1):_vm._e(),(
          _vm.documentsSettings.documentsValidationMode !==
          _vm.fieldValidationMode.NotPrompted
        )?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"documents_file","mode":"eager","slim":"","rules":_vm.getValidationRules(_vm.documentsSettings.documentsValidationMode)},scopedSlots:_vm._u([{key:"default",fn:function(validationData){return [_c('file-input',{attrs:{"multiple":"","label":_vm.getLabel(
                'application.additionalDocuments',
                _vm.documentsSettings.documentsValidationMode
              ),"accept":_vm.allowedFileTypes,"upload-endpoint":'application-file',"download-endpoint":'application-file',"additional-values":{ fileType: _vm.fileType.Document },"file-prefix":_vm.formId,"validation-data":validationData},model:{value:(_vm.application.documents),callback:function ($$v) {_vm.$set(_vm.application, "documents", $$v)},expression:"application.documents"}})]}}],null,false,3228928558)})],1):_vm._e()],1)],1),_c('div',{staticClass:"mt-4"},[_c('i',{staticClass:"text-sm text--muted"},[_vm._v(_vm._s(_vm.$t('application.fileSizeInfo')))])]),_c('cv-parser-dialog',{model:{value:(_vm.parsing),callback:function ($$v) {_vm.parsing=$$v},expression:"parsing"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }