export default Object.freeze({
  QuestionType: {
    Language: 1,
    Competency: 2,
    Experience: 4,
    YesNo: 8,
    Text: 16,
    PitchYouStep: 32,
    GroupTitle: 64
  }
});
