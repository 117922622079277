<template>
  <section class="hero" style="position: relative; z-index: 0">
    <div
      v-if="heroVideoUrl && !heroImageAvailable"
      class="hero__video-wrapper"
      style="
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      "
    >
      <video
        aria-hidden="true"
        playsinline
        autoplay
        muted
        loop
        style="position: absolute; height: auto; min-height: 100%"
      >
        <source :src="heroVideoPath" type="video/mp4" />
      </video>
    </div>
    <v-container class="hero__filter-container">
      <div
        class="d-flex hero__filter-container__mobile-filter"
        v-if="hasMobileFilter"
      >
        <fulltext-search filled class="mr-4"></fulltext-search>
        <mobile-filter></mobile-filter>
      </div>
    </v-container>
    <v-container class="hero__claim-wrapper">
      <h1 class="d-block" v-html="heroClaim"></h1>

      <v-row v-if="hasSearchField" class="d-none d-md-flex">
        <v-col cols="12" class="d-flex">
          <fulltext-search
            filled
            bordered
            class="flex-grow-1"
          ></fulltext-search>

          <template v-if="hasJoboffersButton">
            <v-btn
              :to="{ name: 'jobs', query: { show: 'all' } }"
              class="ml-4 hero__filter-container__all-jobs-button"
              >{{ $t('jobs.allJoboffers') }}</v-btn
            >
          </template>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import mobileFilter from '@/components/jobs/mobile-filter.vue';
import fulltextSearch from '@/components/fulltext-search.vue';

import { mapState } from 'vuex';

export default {
  props: {
    heroClaim: {
      type: String,
      required: false,
      default: ''
    },

    hasSearchField: {
      type: Boolean,
      required: false,
      default: false
    },

    hasJoboffersButton: {
      type: Boolean,
      required: false,
      default: false
    },

    hasMobileFilter: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data: () => ({
    apiUrl: process.env.VUE_APP_API_URL
  }),

  computed: {
    ...mapState('companyModule', {
      heroVideoUrl: 'heroVideoUrl',
      slug: 'slug'
    }),

    ...mapState({
      heroImageUrl: 'heroImageUrl'
    }),

    heroVideoPath() {
      let url = '';
      if (this.heroVideoUrl) {
        url = `${this.apiUrl}/file/public?slug=${this.slug}&fileName=${this.heroVideoUrl}`;
      }

      return url;
    },

    heroImageAvailable() {
      return this.heroImageUrl;
    }
  },

  components: {
    mobileFilter,
    fulltextSearch
  }
};
</script>
