<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="`yesno_question_${_uid}`"
    mode="eager"
    slim
    rules="required"
  >
    <v-select
      v-model="questionValue"
      outlined
      flat
      hide-details="auto"
      append-icon="mdi-directions-fork"
      bottom
      clearable
      :placeholder="$t('pleaseSelect')"
      :items="options"
      :error-messages="errors"
    ></v-select>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: ''
    },

    question: {
      type: Object,
      required: true
    }
  },

  computed: {
    options() {
      return [
        { text: this.$t('application.yesNoAnswer.1'), value: 1 },
        { text: this.$t('application.yesNoAnswer.0'), value: 0 }
      ];
    },

    questionValue: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit('input', val);
      }
    }
  }
};
</script>
