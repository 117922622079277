<template>
  <div>
    <component
      v-model="fieldValue"
      :is="fieldComponent"
      :item="basicDataItem"
    ></component>
  </div>
</template>

<script>
import fieldTypeEnums from '@/enum/application-field-type.js';

import textField from '@/components/application/basic-data/text-field.vue';
import numericField from '@/components/application/basic-data/numeric-field.vue';
import dateField from '@/components/application/basic-data/date-field.vue';
import selectField from '@/components/application/basic-data/select-field.vue';
import salaryField from '@/components/application/basic-data/salary-field.vue';
import rangeField from '@/components/application/basic-data/range-field.vue';

export default {
  props: {
    value: {
      type: [String, Number],
      required: true
    },

    basicDataItem: {
      type: Object,
      required: true
    }
  },

  computed: {
    fieldType() {
      return fieldTypeEnums.ApplicationFieldTypes;
    },

    fieldValue: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit('input', val);
      }
    },

    fieldComponent() {
      // TODO: use enumeration
      if (this.basicDataItem.fieldType === this.fieldType.Text) {
        return 'textField';
      } else if (this.basicDataItem.fieldType === this.fieldType.Numeric) {
        return 'numericField';
      } else if (this.basicDataItem.fieldType === this.fieldType.Date) {
        return 'dateField';
      } else if (this.basicDataItem.fieldType === this.fieldType.Select) {
        return 'selectField';
      } else if (this.basicDataItem.fieldType === this.fieldType.Salary) {
        return 'salaryField';
      } else if (this.basicDataItem.fieldType === this.fieldType.Range) {
        return 'rangeField';
      }

      return '';
    }
  },

  components: {
    dateField,
    textField,
    numericField,
    selectField,
    salaryField,
    rangeField
  }
};
</script>
