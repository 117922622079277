<template>
  <div class="apply-form__section apply-form__documents">
    <h3>{{ $t('application.documents') }}</h3>

    <v-row
      v-if="
        documentsSettings.cvValidationMode !== fieldValidationMode.NotPrompted
      "
    >
      <v-col cols="12">
        <ValidationProvider
          v-slot="validationData"
          name="cv_file"
          mode="eager"
          slim
          :rules="getValidationRules(documentsSettings.cvValidationMode)"
        >
          <file-input
            v-model="application.cv"
            :label="
              getLabel('application.cv', documentsSettings.cvValidationMode)
            "
            :accept="allowedFileTypes"
            :upload-endpoint="'application-file'"
            :download-endpoint="'application-file'"
            :additional-values="{ fileType: fileType.CV }"
            :file-prefix="formId"
            :validation-data="validationData"
          ></file-input>
        </ValidationProvider>
      </v-col>
    </v-row>

    <v-row
      v-if="
        documentsSettings.documentsValidationMode !==
        fieldValidationMode.NotPrompted
      "
    >
      <v-col cols="12">
        <ValidationProvider
          v-slot="validationData"
          name="documents_file"
          mode="eager"
          slim
          :rules="getValidationRules(documentsSettings.documentsValidationMode)"
        >
          <file-input
            v-model="application.documents"
            multiple
            :label="
              getLabel(
                'application.additionalDocumentsAndLetter',
                documentsSettings.documentsValidationMode
              )
            "
            :accept="allowedFileTypes"
            :upload-endpoint="'application-file'"
            :download-endpoint="'application-file'"
            :additional-values="{ fileType: fileType.Document }"
            :file-prefix="formId"
            :validation-data="validationData"
          ></file-input>
        </ValidationProvider>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <em class="muted">{{ $t('application.fileSizeInfo') }}</em>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { eventBus } from '@/event-bus.js';

import fileInput from '../file-input.vue';

import fileTypeEnums from '@/enum/application-file-type.js';
import validationModeEnums from '@/enum/field-validation-mode.js';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    documentsSettings: {
      type: Object,
      required: true
    },

    jobofferId: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    cv: null,
    documents: [],
    allowedFileTypes: 'image/*, .pdf, .docx, .doc'
  }),

  computed: {
    ...mapState('applicationModule', {
      formId: 'formId'
    }),

    application: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },

    cvFile() {
      return this.application.cv;
    },

    fileNotEmptyRule() {
      return [
        (v) => {
          if (v) {
            return true;
          }

          return this.$t('application.fieldIsMandatory');
        },

        (v) => {
          if (v && v.length > 0) {
            return true;
          }

          return this.$t('application.fieldIsMandatory');
        }
      ];
    },

    fieldValidationMode() {
      return validationModeEnums.FieldValidationModes;
    },

    fileType() {
      return fileTypeEnums.ApplicationFileTypes;
    }
  },

  methods: {
    ...mapActions('applicationModule', {
      parseCvFile: 'parseCvFile'
    }),

    async fileUploadedHandler() {
      if (this.cvFile.length > 0) {
        this.parsing = true;
        this.$emit('beginParsing');

        const cvDataRequest = {
          application: this.application,
          objectId: this.cvFile[0].objectName,
          formId: this.formId,
          jobofferId: this.jobofferId
        };

        let result = null;
        try {
          result = await this.parseCvFile(cvDataRequest);
          this.$emit('file-parsed', result);
        } catch {
          eventBus.$emit(
            'errorMessage',
            'Es ist ein Fehler beim Auslesen des Lebenslaufs aufgetreten'
          );
        }

        this.parsing = false;
        this.$emit('endParsing');
      }
    },

    getLabel(labelKey, validationMode) {
      let label = this.$t(labelKey);
      if (validationMode === this.fieldValidationMode.Mandatory) {
        label += '*';
      }

      return label;
    },

    getValidationRules(validationMode) {
      if (validationMode === this.fieldValidationMode.Mandatory) {
        return 'required|fileUploaded';
      }

      return '';
    }
  },

  components: {
    fileInput
  }
};
</script>
