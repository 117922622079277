<template>
  <div>
    <questions-chapter
      v-model="questions"
      v-for="chapterIndex in chapterIndexes"
      class="questions-form"
      :key="`question_chapter_${chapterIndex}`"
      :chapter-index="chapterIndex"
      :chapter-title="getChapterTitle(chapterIndex)"
      :short-apply="shortApply"
    ></questions-chapter>

    <!-- <additional-questions v-model="individualQuestions"></additional-questions> -->
  </div>
</template>

<script>
import questionsChapter from './questions-chapter.vue';
// import additionalQuestions from './additional-questions-form.vue';

export default {
  props: {
    value: {
      type: Array,
      required: true
    },

    shortApply: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    individualQuestions: []
  }),

  watch: {
    individualQuestions: {
      handler: function () {
        this.emitValue();
      },

      deep: true
    }
  },

  computed: {
    chapterIndexes() {
      const indexes = this.value
        .map((x) => x.chapterIndex)
        .sort((a, b) => a - b);
      return [...new Set(indexes)];
    },

    questions: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
        // this.emitValue();
      }
    }
  },

  methods: {
    emitValue() {
      const result = this.questions;
      for (const item of this.individualQuestions) {
        result.push({
          chapterIndex: 0,
          chapterLabel: [],
          code: item.languageId,
          languageId: item.languageId,
          questionId: -1,
          questionLabel: [],
          questionType: item.questionType,
          sortIndex: 9999,
          value: item.value
        });
      }

      this.$emit('input', result);
    },

    getChapterTitle(chapterIndex) {
      const chapterQuestion = this.questions.find(
        (x) => x.chapterIndex === chapterIndex
      );
      return chapterQuestion.chapterLabel;
    }
  },

  components: {
    // additionalQuestions,
    questionsChapter
  }
};
</script>
