<template>
  <div v-html="localize(htmlContent)"></div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    htmlContent() {
      if (!this.data.text || this.data.text.length === 0) {
        return '';
      }

      return this.data.text;
    }
  }
};
</script>
