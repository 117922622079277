<template>
  <div class="content-box job-benefits-list">
    <h2 v-if="hasTitle" class="text-center mb-8">
      <strong>{{ title }}</strong>
    </h2>

    <v-row class="justify-center">
      <v-col
        cols="6"
        sm="6"
        md="4"
        :lg="maxColumns"
        v-for="(benefit, index) in benefits"
        :key="`company_benefits_${index}`"
      >
        <benefit-item :benefit="benefit"></benefit-item>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import benefitItem from './job-benefit-item.vue';

export default {
  props: {
    data: {
      type: Object,
      required: true
    },

    maxColumns: {
      type: Number,
      required: false,
      default: 4
    }
  },

  computed: {
    ...mapState('jobsModule', {
      jobDetails: 'currentJobDetails'
    }),

    benefits() {
      return this.jobDetails.benefits;
    },

    hasTitle() {
      return (
        this.data.title &&
        Object.keys(this.data.title).length > 0 &&
        this.localize(this.data.title) !== ''
      );
    },

    title() {
      if (Array.isArray(this.data.title)) {
        return this.localize(this.data.title);
      } else {
        return this.localize(this.data.title[0]);
      }
    }
  },

  components: {
    benefitItem
  }
};
</script>
