<template>
  <div class="content-box" :class="[colorClass, hasCenteredClass]">
    <template>
      <v-row>
        <v-col
          v-for="n in data.numColumns"
          :key="`advertContent_title_${n}`"
          cols="12"
          :md="columnSize"
        >
          <div v-if="getTitle(n) !== ''" class="content-box__title">
            <strong>{{ stripHtml(getTitle(n)) }}</strong>
          </div>

          <div class="content-box__content">
            <div v-html="getText(n)"></div>
          </div>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState('companyModule', {
      slug: 'slug'
    }),

    columnSize() {
      if (this.data.numColumns === 2) {
        return '6';
      } else if (this.data.numColumns === 3) {
        return '4';
      } else {
        return '12';
      }
    },

    colorClass() {
      if (this.data.variant === 1) {
        return 'content-box__color-primary';
      } else if (this.data.variant === 2) {
        return 'content-box__color-secondary';
      } else {
        return '';
      }
    },

    hasCenteredClass() {
      if (this.data.isCentered) {
        return 'text-center';
      } else {
        return '';
      }
    }
  },

  methods: {
    getTitle(colIndex) {
      const title = this.data.title[colIndex - 1];
      if (!title) {
        return '';
      }

      return this.localize(title);
    },

    getText(colIndex) {
      const text = this.data.text[colIndex - 1];
      if (!text) {
        return '';
      }

      return this.localize(text);
    },

    stripHtml(htmlContent) {
      const tmpElement = document.createElement('DIV');
      tmpElement.innerHTML = htmlContent;
      return tmpElement.textContent || tmpElement.innerText;
    }
  }
};
</script>
