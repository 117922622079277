<template>
  <div class="apply-form__section apply-form__privacy-policy">
    <h3>{{ $t('application.summary') }}</h3>
    <p>{{ $t('application.summaryInfo') }}</p>

    <div class="d-block d-md-flex align-center">
      <div class="text--muted text-sm flex-grow-1">
        * {{ $t('application.mandatoryFields') }}
      </div>

      <v-btn v-if="false" :block="blockButton" class="secondary d-md-inline mt-4 mt-md-0">
        {{ $t('application.downloadApplication') }}
      </v-btn>

      <v-btn
        @click="$emit('submit')"
        :block="blockButton"
        :loading="submitting"
        color="primary"
        class="ml-md-4 mt-4 mt-md-0 d-md-inline"
      >
        {{ $t('application.sendApplication') }}
        <v-icon class="ml-4">mdi-send</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    submitting: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    blockButton() {
      return (
        this.$vuetify.breakpoint.name === 'xs' ||
        this.$vuetify.breakpoint.name === 'sm'
      );
    }
  }
};
</script>
