<template>
  <section class="jobs-overview">
    <content-area
      v-if="jobsDefaultTopContent && jobsDefaultTopContent.length > 0"
      :content="jobsDefaultTopContent"
    ></content-area>

    <content-area
      v-if="content && jobsContentPosition === 'top'"
      :content="content"
    ></content-area>

    <v-row>
      <v-col cols="12">
        <div class="jobs-overview content-box dense alternate text-left">
          <v-row dense id="jobs-overview-header" class="mb-2 fixed-menu-anchor">
            <v-col cols="12" sm="5" class="dense d-flex align-center">
              <strong class="job-overview__item-count"
                >{{ numJobs }} {{ $tc('jobs.joboffers', numJobs) }}</strong
              >
            </v-col>
            <v-col cols="12" sm="7" class="dense text-right">
              <div v-if="jobsLayoutToggle" class="button-group">
                <v-btn
                  :class="{ active: jobsLayout === 'list' }"
                  @click="setJobsLayout('list')"
                >
                  <v-icon>mdi-view-headline</v-icon>
                </v-btn>

                <v-btn
                  :class="{ active: jobsLayout === 'tiles' }"
                  @click="setJobsLayout('tiles')"
                >
                  <v-icon>mdi-view-grid</v-icon>
                </v-btn>
              </div>

              <jobalert-dialog></jobalert-dialog>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              :md="colsMd"
              :lg="colsLg"
              v-for="job in visibleJoboffers"
              :key="`joboffer_item_${job.jobofferId}`"
            >
              <component :is="jobItemComponent" :joboffer="job"></component>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <content-area
      v-if="content && jobsContentPosition === 'bottom'"
      :content="content"
    ></content-area>

    <content-area
      v-if="jobsDefaultBottomContent && jobsDefaultBottomContent.length > 0"
      :content="jobsDefaultBottomContent"
    ></content-area>
  </section>
</template>

<script>
import jobofferListItem from '@/components/jobs/joboffer-list-item.vue';
import jobofferTileItem from '@/components/jobs/joboffer-tile-item.vue';
import contentArea from '@/components/content/content-area.vue';
import jobalertDialog from '@/components/jobalert/signup-dialog.vue';

import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';

export default {
  mounted() {
    if (this.defaultClaim && this.defaultClaim.length > 0) {
      this.setHeroClaim(this.defaultClaim);
    }

    const url = window.location.href;
    let searchString = window.location.search;
    if (!searchString && url.indexOf('?') > -1) {
      const parts = url.split('?');
      if (parts.length > 0) {
        searchString = `?${parts[1]}`;
      }
    }

    const searchParams = new URLSearchParams(searchString);
    if (searchParams.has('show') && searchParams.get('show') === 'all') {
      this.clearFilters();
    }
  },

  computed: {
    ...mapState({
      routeImages: 'routeImages'
    }),

    ...mapState('jobsModule', {
      filters: 'filters'
    }),

    ...mapState('companyModule', {
      isInternal: 'isInternal',
      jobsContentPosition: 'jobsContentPosition',
      jobsDefaultContentPosition: 'jobsDefaultContentPosition',
      jobsDefaultTopContent: 'jobsDefaultTopContent',
      jobsDefaultBottomContent: 'jobsDefaultBottomContent',
      jobsLayout: 'jobsLayout',
      defaultClaim: 'defaultClaim',
      jobsLayoutToggle: 'jobsLayoutToggle'
    }),

    ...mapGetters('jobsModule', {
      joboffers: 'getJoboffers',
      content: 'getContent'
    }),

    visibleJoboffers() {
      return this.joboffers.filter((x) => !x.isBlacklisted);
    },

    colsMd() {
      if (this.jobsLayout === 'tiles') {
        return 6;
      }

      return 12;
    },

    colsLg() {
      if (this.jobsLayout === 'tiles') {
        return 6;
      }

      return 12;
    },

    jobItemComponent() {
      if (this.jobsLayout === 'tiles') {
        return 'jobofferTileItem';
      }

      return 'jobofferListItem';
    },

    numJobs() {
      return this.visibleJoboffers.length;
    }
  },

  created() {
    const routeImage = this.routeImages['jobs'];
    if (routeImage) {
      this.setHeroImage(routeImage);
    }
  },

  methods: {
    ...mapMutations({
      setHeroClaim: 'setHeroClaim',
      setHeroImage: 'setHeroImage',
      setJobsLayout: 'companyModule/setJobsLayout'
    }),

    ...mapActions('jobsModule', {
      clearFilters: 'clearFilters'
    })
  },

  components: {
    contentArea,
    jobalertDialog,
    jobofferListItem,
    jobofferTileItem
  }
};
</script>
