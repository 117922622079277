<template>
  <v-expansion-panels multiple>
    <v-expansion-panel
      v-for="(item, i) in data.content"
      :key="`accordion_panel_${i}`"
    >
      <v-expansion-panel-header>{{
        localize(item.title)
      }}</v-expansion-panel-header>

      <v-expansion-panel-content>
        <div class="mt-4" v-html="localize(item.text)"></div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  }
};
</script>
