import bikeLeasing from '@/components/icons/icon-bike-leasing.vue';
import bonus from '@/components/icons/icon-bonus.vue';
import car from '@/components/icons/icon-car.vue';
import daycare from '@/components/icons/icon-daycare.vue';
import disabilityInsurance from '@/components/icons/icon-disability-insurance.vue';
import discount from '@/components/icons/icon-discount.vue';
import events from '@/components/icons/icon-events.vue';
import fitness from '@/components/icons/icon-fitness.vue';
import fitnessMembership from '@/components/icons/icon-fitness-membership.vue';
import homeoffice from '@/components/icons/icon-homeoffice.vue';
import jobticket from '@/components/icons/icon-jobticket.vue';
import location from '@/components/icons/icon-location.vue';
import lunch from '@/components/icons/icon-lunch.vue';
import parking from '@/components/icons/icon-parking.vue';
import pension from '@/components/icons/icon-pension.vue';
import publicTransport from '@/components/icons/icon-public-transport.vue';
import sabbath from '@/components/icons/icon-sabbath.vue';
import salary from '@/components/icons/icon-salary.vue';
import snacks from '@/components/icons/icon-snacks.vue';
import training from '@/components/icons/icon-training.vue';
import vacation from '@/components/icons/icon-vacation.vue';
import workingTime from '@/components/icons/icon-working-time.vue';
import workplace from '@/components/icons/icon-workplace.vue';
import contractOffer from '@/components/icons/icon-contract-offer.vue';
import judgement from '@/components/icons/icon-judgement.vue';
import onlineApplication from '@/components/icons/icon-online-application.vue';
import videoInterview from '@/components/icons/icon-video-interview.vue';
import interview from '@/components/icons/icon-interview.vue';
import endDate from '@/components/icons/icon-date-end.vue';
import dateTime from '@/components/icons/icon-date-timed.vue';
import info from '@/components/icons/icon-info.vue';
import map from '@/components/icons/icon-map-pin.vue';
import money from '@/components/icons/icon-money.vue';
import number from '@/components/icons/icon-number.vue';
import startDate from '@/components/icons/icon-start-date.vue';
import time from '@/components/icons/icon-time.vue';
import facebook from '@/components/icons/icon-facebook.vue';
import instagram from '@/components/icons/icon-instagram.vue';
import linkedin from '@/components/icons/icon-linkedin.vue';
import xing from '@/components/icons/icon-xing.vue';
import youtube from '@/components/icons/icon-youtube.vue';

export default {
  endDate: {
    component: endDate
  },

  dateTime: {
    component: dateTime
  },

  info: {
    component: info
  },

  map: {
    component: map
  },

  money: {
    component: money
  },

  number: {
    component: number
  },

  startDate: {
    component: startDate
  },

  time: {
    component: time
  },

  bikeLeasing: {
    component: bikeLeasing
  },
  bonus: {
    component: bonus
  },
  car: {
    component: car
  },
  daycare: {
    component: daycare
  },
  disabilityInsurance: {
    component: disabilityInsurance
  },
  discount: {
    component: discount
  },
  events: {
    component: events
  },
  fitness: {
    component: fitness
  },
  fitnessMembership: {
    component: fitnessMembership
  },
  homeoffice: {
    component: homeoffice
  },
  jobticket: {
    component: jobticket
  },
  location: {
    component: location
  },
  lunch: {
    component: lunch
  },
  parking: {
    component: parking
  },
  pension: {
    component: pension
  },
  publicTransport: {
    component: publicTransport
  },
  subbath: {
    component: sabbath
  },
  salary: {
    component: salary
  },
  snacks: {
    component: snacks
  },
  training: {
    component: training
  },
  vacation: {
    component: vacation
  },
  workingTime: {
    component: workingTime
  },
  workplace: {
    component: workplace
  },
  contractOffer: {
    component: contractOffer
  },
  judgement: {
    component: judgement
  },
  interview: {
    component: interview
  },
  onlineApplication: {
    component: onlineApplication
  },
  videoInterview: {
    component: videoInterview
  },
  facebook: {
    component: facebook
  },
  instagram: {
    component: instagram
  },
  linkedin: {
    component: linkedin
  },
  xing: {
    component: xing
  },
  youtube: {
    component: youtube
  }
};
