<template>
  <v-img :src="imageUrl" class="image-box__image"></v-img>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState('companyModule', {
      slug: 'slug'
    }),

    imageUrl() {
      return `${process.env.VUE_APP_API_URL}/file/public?slug=${this.slug}&fileName=${this.data.imageName}`;
    }
  }
};
</script>
