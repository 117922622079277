<template>
  <div>
    <v-tooltip
      top
      v-if="joboffer.pitchYouApplyUrl && joboffer.pitchYouApplyUrl !== ''"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          :href="joboffer.pitchYouApplyUrl"
          target="_blank"
          class="mr-2 px-6 whatsapp-button"
        >
          <v-icon>mdi-whatsapp</v-icon>
          <span class="ml-2" v-if="!dense"> Mit WhatsApp bewerben </span>
        </v-btn>
      </template>

      <span>Mit WhatsApp bewerben</span>
    </v-tooltip>

    <v-btn
      v-if="joboffer.hasExternalApplicationForm"
      :href="joboffer.applyUrl"
      target="_blank"
    >
      {{ $t('jobs.applyNow') }}
      <v-icon class="ml-2">mdi-send</v-icon>
    </v-btn>

    <apply-dialog
      v-else-if="joboffer.hasShortApplicationForm"
      :joboffer="joboffer"
      inline-button
    ></apply-dialog>

    <v-btn
      v-else
      :to="{ name: 'apply', params: { jobofferId: joboffer.jobofferId } }"
    >
      {{ $t('jobs.applyNow') }}
      <v-icon class="ml-2">mdi-send</v-icon>
    </v-btn>
  </div>
</template>

<script>
import applyDialog from '@/components/application/apply-dialog.vue';

export default {
  props: {
    joboffer: {
      type: Object,
      required: true
    },

    dense: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  components: {
    applyDialog
  }
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 360px) {
  .whatsapp-button {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
