var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-box content-box__dense content-grid__wrapper"},[_c('div',{staticClass:"content-grid"},_vm._l((_vm.items),function(item,index){return _c('div',{key:`grid_item_${index}`,class:`content-grid__item ${_vm.getClass(item.route)}`,style:(`
        background-image: url(${_vm.apiUrl}/file/public?slug=${_vm.slug}&fileName=${
        item.image
      });
        grid-column-start: ${item.colStart};
        grid-column-end: ${item.colEnd};
        grid-row-start: ${item.rowStart};
        grid-row-end: ${item.rowEnd};
        //aspect-ratio: ${_vm.getAspectRatio(item.colSpan, item.rowSpan)};
      `),on:{"click":function($event){return _vm.handleClick(item)}}},[_c('div',{staticClass:"content-grid__item__label"},[_c('h3',[_vm._v(_vm._s(_vm.localize(item.label)))])]),_c('div',{staticClass:"content-grid__item__overlay"},[(item.icon)?_c('v-btn',{attrs:{"small":""}},[(item.icon)?_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(item.icon))]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"v-overlay__content__label"},[_c('h3',[_vm._v(_vm._s(_vm.localize(item.label)))]),(_vm.localize(item.subtitle) !== '')?_c('div',[_vm._v(" "+_vm._s(_vm.localize(item.subtitle))+" ")]):_vm._e()])],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }