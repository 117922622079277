import contentSiteService from '@/services/ContentSiteService';

export default {
  async getSite(context, request) {
    return await contentSiteService.getContentSite(
      request.slug,
      request.siteName
    );
  }
};
