<template>
  <v-dialog v-model="showDialog" persistent max-width="550px">
    <v-card class="has--title">
      <v-card-title> Lebenslauf wird ausgelesen </v-card-title>
      <v-card-text>
        <div class="text-center mb-8">
          <v-progress-circular
            :size="75"
            :width="8"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>

        <div>
          Der Lebenslauf wird ausgelesen und die Daten in das Formular
          übernommen.
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    showDialog: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>
