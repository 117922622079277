var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"apply-form__section apply-form__documents"},[_c('h3',[_vm._v(_vm._s(_vm.$t('application.documents')))]),(
      _vm.documentsSettings.cvValidationMode !== _vm.fieldValidationMode.NotPrompted
    )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"cv_file","mode":"eager","slim":"","rules":_vm.getValidationRules(_vm.documentsSettings.cvValidationMode)},scopedSlots:_vm._u([{key:"default",fn:function(validationData){return [_c('file-input',{attrs:{"label":_vm.getLabel('application.cv', _vm.documentsSettings.cvValidationMode),"accept":_vm.allowedFileTypes,"upload-endpoint":'application-file',"download-endpoint":'application-file',"additional-values":{ fileType: _vm.fileType.CV },"file-prefix":_vm.formId,"validation-data":validationData},model:{value:(_vm.application.cv),callback:function ($$v) {_vm.$set(_vm.application, "cv", $$v)},expression:"application.cv"}})]}}],null,false,4071908424)})],1)],1):_vm._e(),(
      _vm.documentsSettings.documentsValidationMode !==
      _vm.fieldValidationMode.NotPrompted
    )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"documents_file","mode":"eager","slim":"","rules":_vm.getValidationRules(_vm.documentsSettings.documentsValidationMode)},scopedSlots:_vm._u([{key:"default",fn:function(validationData){return [_c('file-input',{attrs:{"multiple":"","label":_vm.getLabel(
              'application.additionalDocumentsAndLetter',
              _vm.documentsSettings.documentsValidationMode
            ),"accept":_vm.allowedFileTypes,"upload-endpoint":'application-file',"download-endpoint":'application-file',"additional-values":{ fileType: _vm.fileType.Document },"file-prefix":_vm.formId,"validation-data":validationData},model:{value:(_vm.application.documents),callback:function ($$v) {_vm.$set(_vm.application, "documents", $$v)},expression:"application.documents"}})]}}],null,false,72334811)})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('em',{staticClass:"muted"},[_vm._v(_vm._s(_vm.$t('application.fileSizeInfo')))])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }