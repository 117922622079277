<template>
  <div>
    <v-container class="top-filter">
      <v-banner sticky elevation="3" class="white">
        <desktop-filter></desktop-filter>
      </v-banner>
    </v-container>

    <hero-element :heroClaim="heroClaim"></hero-element>

    <v-main class="content-area">
      <v-container>
        <router-view v-slot="{ Component }">
          <transition name="fade">
            <component :is="Component" />
          </transition>
        </router-view>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import desktopFilter from '@/components/jobs/desktop-filter.vue';
import heroElement from '@/components/hero-element.vue';

export default {
  props: {
    heroClaim: {
      type: String,
      required: true
    }
  },

  components: {
    desktopFilter,
    heroElement
  }
};
</script>
