<template>
  <div @dragover="handleDragOver">
    <v-main class="content-area">
      <v-container>
        <v-row>
          <v-col cols="12">
            <router-view v-slot="{ Component }">
              <transition name="fade">
                <component :is="Component" />
              </transition>
            </router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { eventBus } from '@/event-bus.js';

import heroElement from '@/components/hero-element.vue';

export default {
  props: {
    heroClaim: {
      type: String,
      required: true
    }
  },

  methods: {
    handleDragOver() {
      eventBus.$emit('dragging');
    }
  },

  components: {
    heroElement
  }
};
</script>

<style lang="scss">
#app.default.v-application.apply-form-layout {
  background-image: none !important;

  .v-main.content-area {
    margin-top: 192px;
  }
}
</style>
