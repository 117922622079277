<template>
  <div
    class="company-benefit"
    @mouseover="showDescription = true"
    @mouseout="showDescription = false"
  >
    <div
      class="company-benefit__front d-block d-md-flex text-center text-md-left align-md-center"
    >
      <div class="company-benefit__icon" v-if="!benefit.customImageName">
        <!-- <v-icon x-large color="primary">mdi-{{ benefit.iconName }}</v-icon> -->
        <v-icon x-large color="primary">{{ benefit.iconName }}</v-icon>
      </div>

      <div v-else class="company-benefit__custom-icon">
        <v-img
          :src="`${apiUrl}/file/public?slug=${slug}&fileName=${benefit.customImageName}`"
        ></v-img>
      </div>

      <div class="company-benefit__label">
        {{ localize(benefit.benefitName) }}
      </div>

      <div class="company-benefit__overlay" v-if="hasDescription">
        <div v-html="localize(benefit.itemDescription)"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    benefit: {
      type: Object,
      required: true
    },

    slug: {
      type: String,
      required: true
    }
  },

  data: () => ({
    apiUrl: process.env.VUE_APP_API_URL,
    showDescription: false
  }),

  computed: {
    hasDescription() {
      return this.localize(this.benefit.itemDescription) !== '';
    }
  }
};
</script>

<style scoped>
.company-benefit {
  position: relative;
  overflow: hidden;
}

.company-benefit__overlay {
  font-size: var(--font-size-sm);
  background-color: var(--accent);
  color: var(--primary-complement);
  position: absolute;
  top: 0;
  left: -1000px;
  right: 100%;
  bottom: 0;
  -moz-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
  -webkit-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
  transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.company-benefit:hover .company-benefit__overlay {
  left: 0;
  right: 0;
}
</style>
