<template>
  <div class="content-box content-box__color-secondary content-box__dense">
    <div class="apply-form__section apply-form__personal-data mb-0 pb-0">
      <h3>{{ $t('application.additionalQuestions_title') }}</h3>

      <p>
        {{ $t('application.additionalQuestions_info') }}
      </p>

      <v-row
        v-for="(item, index) in additionalQuestions"
        :key="`additional_question_${index}`"
        class="d-flex align-center"
      >
        <v-col cols="12" md="6" class="d-flex">
          <ValidationProvider
            v-slot="{ errors }"
            :name="`question_name_question_${_uid}_${index}`"
            mode="eager"
            slim
            rules="required"
          >
            <v-select
              v-model="item.code"
              outlined
              flat
              hide-details="auto"
              :items="getItems(item)"
              :placeholder="getPlaceholder(item)"
              :append-icon="getIcon(item)"
              :error-messages="errors"
            ></v-select>
          </ValidationProvider>
        </v-col>

        <v-col cols="12" md="5" class="mb-4 mb-md-0">
          <component
            v-model="item.value"
            :is="getQuestionComponent(item)"
            :question="item"
          ></component>
        </v-col>
        <v-col cols="12" md="1">
          <v-btn small color="secondary" @click="removeQuestion(index)">
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn
            small
            color="secondary"
            class="mr-4 mb-4 mb-md-0"
            :block="blockButton"
            @click="addLanguageQuestion"
          >
            <v-icon class="mr-2">mdi-account-voice</v-icon>
            {{ $t('application.additionalQuestions_addLanguage') }}
          </v-btn>

          <v-btn
            small
            color="secondary"
            :block="blockButton"
            @click="addSkillQuestion"
          >
            <v-icon class="mr-2">mdi-briefcase-outline</v-icon>
            {{ $t('application.additionalQuestions_addSkill') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import languageQuestion from './langauge-question.vue';
import skillQuestion from './skill-question.vue';
import questionTypeEnums from '@/enum/question-type.js';
import { mapActions, mapState } from 'vuex';

export default {
  props: {
    value: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    additionalQuestions: [],
    languages: [],
    skills: [],
    preventEmit: false
  }),

  computed: {
    ...mapState('companyModule', {
      slug: 'slug'
    }),

    blockButton() {
      return (
        this.$vuetify.breakpoint.name === 'xs' ||
        this.$vuetify.breakpoint.name === 'sm'
      );
    },

    questionType() {
      return questionTypeEnums.QuestionType;
    }
  },

  watch: {
    additionalQuestions: {
      handler: function (val) {
        for (const item of val) {
          let questionText = '';

          if (item.questionType === this.questionType.Language) {
            questionText = this.languages.find(
              (x) => x.value === item.code
            )?.text;
          }

          if (item.questionType === this.questionType.Competency) {
            questionText = this.skills.find((x) => x.value === item.code)?.text;
          }

          item.questionText = questionText;
        }

        if (!this.preventEmit) {
          this.$emit('input', val);
        }

        this.preventEmit = false;
      },
      deep: true
    },

    async value() {
      if (this.value && this.value.length > 0) {
        if (this.skills.length === 0) {
          const items = await this.loadSkills({
            slug: this.slug
          });

          for (const item of Object.keys(items)) {
            this.skills.push({
              text: this.localize(items[item]),
              value: item.toString()
            });
          }
        }

        if (this.languages.length === 0) {
          const items = await this.loadTranslations({
            slug: this.slug,
            translationKey: 'languages'
          });

          for (const item of Object.keys(items)) {
            this.languages.push({
              text: this.localize(items[item]),
              value: item.toString()
            });
          }
        }
      }

      this.preventEmit = true;
      this.additionalQuestions = this.value.map((x) => {
        return {
          code: x.code,
          questionText: x.questionText,
          questionType: x.questionType,
          value: x.value
        };
      });
    }
  },

  methods: {
    ...mapActions('companyModule', {
      loadTranslations: 'loadTranslations',
      loadSkills: 'loadSkills'
    }),

    async addLanguageQuestion() {
      if (this.languages.length === 0) {
        const items = await this.loadTranslations({
          slug: this.slug,
          translationKey: 'languages'
        });

        for (const item of Object.keys(items)) {
          this.languages.push({
            text: this.localize(items[item]),
            value: item.toString()
          });
        }
      }

      this.additionalQuestions.push({
        code: '',
        questionText: '',
        questionType: this.questionType.Language,
        value: '-1'
      });
    },

    async addSkillQuestion() {
      if (this.skills.length === 0) {
        const items = await this.loadSkills({
          slug: this.slug
        });

        for (const item of Object.keys(items)) {
          this.skills.push({
            text: this.localize(items[item]),
            value: item.toString()
          });
        }
      }

      this.additionalQuestions.push({
        code: '',
        questionText: '',
        questionType: this.questionType.Competency,
        value: '-1'
      });
    },

    getIcon(questionItem) {
      if (questionItem.questionType === this.questionType.Language) {
        return 'mdi-account-voice';
      } else {
        return 'mdi-suitcase-outline';
      }
    },

    getItems(questionItem) {
      if (questionItem.questionType === this.questionType.Language) {
        return this.languages;
      } else {
        return this.skills;
      }
    },

    getPlaceholder(questionItem) {
      if (questionItem.questionType === this.questionType.Language) {
        return 'Sprache auswählen';
      } else {
        return 'Kompetenz auswählen';
      }
    },

    getQuestionComponent(questionItem) {
      if (questionItem.questionType === this.questionType.Language) {
        return 'language-question';
      } else {
        return 'skill-question';
      }
    },

    removeQuestion(index) {
      this.additionalQuestions.splice(index, 1);
    }
  },

  components: {
    languageQuestion,
    skillQuestion
  }
};
</script>
