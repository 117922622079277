<template>
  <div class="content-box content-box__dense content-grid__wrapper">
    <div class="content-grid">
      <div
        v-for="(item, index) in items"
        :key="`grid_item_${index}`"
        :class="`content-grid__item ${getClass(item.route)}`"
        :style="`
          background-image: url(${apiUrl}/file/public?slug=${slug}&fileName=${
          item.image
        });
          grid-column-start: ${item.colStart};
          grid-column-end: ${item.colEnd};
          grid-row-start: ${item.rowStart};
          grid-row-end: ${item.rowEnd};
          //aspect-ratio: ${getAspectRatio(item.colSpan, item.rowSpan)};
        `"
        @click="handleClick(item)"
      >
        <div class="content-grid__item__label">
          <h3>{{ localize(item.label) }}</h3>
        </div>

        <div class="content-grid__item__overlay">
          <v-btn v-if="item.icon" small>
            <v-icon v-if="item.icon" large>{{ item.icon }}</v-icon>
          </v-btn>
          <div class="v-overlay__content__label">
            <h3>{{ localize(item.label) }}</h3>
            <div v-if="localize(item.subtitle) !== ''">
              {{ localize(item.subtitle) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { eventBus } from '@/event-bus.js';

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    apiUrl: process.env.VUE_APP_API_URL
  }),

  computed: {
    ...mapState('companyModule', {
      slug: 'slug'
    }),

    items() {
      return this.data.images;
    }
  },

  methods: {
    getAspectRatio(colSpan, rowSpan) {
      return colSpan / rowSpan;
    },

    handleClick(item) {
      if (item.contentDialog) {
        eventBus.$emit('show-content-dialog', item.contentPage);
      } else if (item.route) {
        this.goto(item.route);
      }
    },

    goto(routeData) {
      if (!routeData) {
        return;
      }

      this.$router.push({
        name: routeData.name,
        params: routeData.params
      });
    },

    getClass(routeData) {
      return routeData ? 'has-link' : '';
    }
  }
};
</script>
