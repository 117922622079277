<template>
  <div>
    <!-- <hero-element :heroClaim="heroClaim"></hero-element> -->

    <v-main class="content-area">
      <v-container>
        <transition name="fade">
          <router-view v-slot="{ Component }">
            <transition name="fade">
              <component :is="Component" />
            </transition>
          </router-view>
        </transition>
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  props: {
    heroClaim: {
      type: String,
      required: true
    }
  },

  computed: {
    absoluteOverlay() {
      return true;
    }
  }
};
</script>
