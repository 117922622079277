<template>
  <div v-if="video && video.videoUrl" class="content-box advert-video">
    <h2 v-if="hasTitle" class="text-center mb-8">
      <strong>{{ title }}</strong>
    </h2>

    <div class="video-wrapper">
      <a
        :href="video.videoUrl"
        class="yt-video-link"
        target="_blank"
        :title="localize(video.description)"
      >
        <img :src="video.thumbnailUrl" :alt="localize(video.description)" />
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    data: {
      type: Object,
      required: true
    },

    maxColumns: {
      type: Number,
      required: false,
      default: 4
    }
  },

  computed: {
    ...mapState('jobsModule', {
      jobDetails: 'currentJobDetails'
    }),

    video() {
      return this.jobDetails.video;
    },

    hasTitle() {
      return (
        this.data.title &&
        Object.keys(this.data.title).length > 0 &&
        this.localize(this.data.title) !== ''
      );
    },

    title() {
      if (Array.isArray(this.data.title)) {
        return this.localize(this.data.title);
      } else {
        return this.localize(this.data.title[0]);
      }
    }
  }
};
</script>
