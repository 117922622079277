<template>
  <div>
    <v-tooltip
      bottom
      v-if="joboffer.pitchYouApplyUrl && joboffer.pitchYouApplyUrl !== ''"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          :href="joboffer.pitchYouApplyUrl"
          :block="blockButtons || $vuetify.breakpoint.smAndDown"
          target="_blank"
          class="mr-2 px-6 whatsapp-button mb-2 mb-md-0"
          :class="{ 'mb-md-4': blockButtons }"
        >
          <div class="whatsapp-icon">
            <img src="@/assets/icons/whatsapp.png" alt="whatsapp logo" />
          </div>
          <span
            class="ml-2"
            v-if="
              !dense ||
              $vuetify.breakpoint.mdAndDown ||
              (blockButtons && $vuetify.breakpoint.lgAndUp)
            "
          >
            Mit WhatsApp bewerben
          </span>
        </v-btn>
      </template>

      <span>Mit WhatsApp bewerben</span>
    </v-tooltip>

    <v-btn
      v-if="joboffer.hasExternalApplicationForm"
      :href="joboffer.applyUrl"
      :block="blockButtons || $vuetify.breakpoint.smAndDown"
      target="_blank"
    >
      {{ $t('jobs.applyNow') }}
    </v-btn>

    <apply-dialog
      v-else-if="joboffer.hasShortApplicationForm"
      ref="applyDialog"
      :joboffer="joboffer"
      :block="blockButtons"
    ></apply-dialog>

    <v-btn
      v-else
      :to="{ name: 'apply', params: { jobofferId: joboffer.jobofferId } }"
      :block="blockButtons || $vuetify.breakpoint.smAndDown"
    >
      {{ $t('jobs.applyNow') }}
    </v-btn>
  </div>
</template>

<script>
import applyDialog from '@/components/application/apply-dialog.vue';

export default {
  props: {
    joboffer: {
      type: Object,
      required: true
    },

    dense: {
      type: Boolean,
      required: false,
      default: false
    },

    blockButtons: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  methods: {
    openApplyDialog() {
      this.$refs.applyDialog.open();
    }
  },

  components: {
    applyDialog
  }
};
</script>
