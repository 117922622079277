<template>
  <div class="image-slider">
    <div
      v-for="(image, i) in images"
      :key="`advert_image_${i}`"
      class="image-slider__slide fade"
    >
      <img :src="image.imageUrl" class="listing-image" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    interval: 5000,
    slideInterval: null,
    slideIndex: 0
  }),

  mounted() {
    this.startSlideshow();
  },

  unmounted() {
    if (this.slideInterval) {
      console.log('removing header interval');
      clearInterval(this.slideInverval);
    }
  },

  methods: {
    showSlides() {
      const slides = document.querySelectorAll('.image-slider__slide');
      if (!slides || slides.length === 0) {
        clearInterval(this.slideInterval);
        return;
      }

      for (let i = 0; i < slides.length; i++) {
        slides[i].style.display = 'none';
      }

      this.slideIndex++;
      if (this.slideIndex > slides.length) {
        this.slideIndex = 1;
      }

      slides[this.slideIndex - 1].style.display = 'block';
    },

    startSlideshow() {
      this.showSlides();
      this.slideInterval = setInterval(this.showSlides, this.interval);
    }
  }
};
</script>

<style lang="scss" scoped>
.image-slider {
  max-width: 100%;
  margin: auto;
  display: block;

  .image-slider__slide {
    display: none;
    width: 100%;
  }

  .image-slider__slide img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .fade {
    animation-name: fade;
    animation-duration: 1s;
  }

  @keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }
}
</style>
