export const localizationMixin = {
  computed: {
    languageKey() {
      let langKey = 'de-DE';
      if (this.$i18n.locale === 'en') {
        langKey = 'en-US';
      }

      return langKey;
    }
  },

  methods: {
    localize(item) {
      if (item === null || item === undefined || item.length === 0) {
        return '';
      }

      if (!Array.isArray(item)) {
        return item;
      }

      const translation = item.find((x) => x.languageKey === this.languageKey);
      return translation?.translation?.trim() ?? item[0].translation.trim();
    }
  }
};
