<template>
  <div class="apply-form__section apply-form__privacy-policy">
    <h3>{{ $t('privacyPolicy') }}</h3>

    <div v-html="$t('privacyPolicyText')"></div>

    <!-- <i18n path="privacyPolicyText">
      <a v-if="customDataPrivacyUrl" :href="customDataPrivacyUrl" target="_blank">
        {{ $t('here') }}
      </a>

      <router-link v-else :to="{ name: 'privacyPolicy' }">{{
        $t('here')
      }}</router-link>
      
    </i18n> -->

    <div>
      <ValidationProvider
        v-slot="{ errors }"
        name="privacyPolicyConfirmed"
        rules="isTrue"
      >
        <v-checkbox
          v-model="policyAccepted"
          :label="$t('acceptPrivacyPolicy')"
          :error-messages="errors"
        ></v-checkbox>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    ...mapState('companyModule', {
      customDataPrivacyUrl: 'customDataPrivacyUrl'
    }),

    policyAccepted: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
};
</script>
