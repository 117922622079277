<template>
  <div class="content-box testimonial-item">
    <template v-if="data.imageName">
      <div class="testimonial-item__top-image">
        <v-img
          class="round-image"
          :src="`${apiUrl}/file/public?slug=${slug}&fileName=${data.imageName}`"
        ></v-img>
      </div>
    </template>

    <div class="testimonial-item__header">
      <h3>{{ localize(data.title) }}</h3>
      <strong v-if="localize(data.subtitle)">{{
        localize(data.subtitle)
      }}</strong>
    </div>

    <div class="testimonial-item__text">
      <p>{{ localize(data.text) }}</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    apiUrl: process.env.VUE_APP_API_URL
  }),

  computed: {
    ...mapState('companyModule', {
      slug: 'slug'
    })
  }
};
</script>
