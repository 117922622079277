import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import applicationModule from './modules/application/module.js';
import companyModule from './modules/company/module.js';
import jobsModule from './modules/jobs/module.js';
import sitesModule from './modules/sites/module.js';

export default new Vuex.Store({
  state: {
    heroImageUrl: '',
    heroClaim: [{ languageKey: 'de-DE', translation: '' }],
    routeImages: [],
    currentLanguage: 'de-DE'
  },
  getters: {
    heroClaim(state) {
      if (!state.heroClaim) {
        return '';
      }

      const languageClaim = state.heroClaim.filter(
        (x) => x.languageKey === state.currentLanguage
      );
      if (languageClaim.length > 0) {
        return languageClaim[0].translation;
      } else {
        return state.heroClaim[0].translation;
      }
    }
  },
  mutations: {
    setHeroImage(state, url) {
      state.heroImageUrl = url;
    },

    setHeroClaim(state, claim) {
      state.heroClaim = claim;
    },

    setRouteImages(state, routeImages) {
      state.routeImages = routeImages;
    }
  },
  actions: {},
  modules: {
    applicationModule: applicationModule,
    companyModule: companyModule,
    jobsModule: jobsModule,
    sitesModule: sitesModule
  }
});
