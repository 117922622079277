<template>
  <div v-if="joboffer" class="job-details job-details__columns">
    <v-row>
      <v-col cols="12" lg="8">
        <div class="job-details__header-image">
          <header-images :images="joboffer.headerImages"></header-images>

          <div v-if="jobDetails" class="job-details__content-area">
            <h1>{{ localize(joboffer.name) }}</h1>

            <basics
              v-if="$vuetify.breakpoint.mdAndDown"
              :job-details="jobDetails"
              :joboffer="joboffer"
              :buttons="false"
            ></basics>

            <content-area :content="jobDetails.content"></content-area>

            <!-- <benefits :benefits="jobDetails.benefits"></benefits> -->

            <div v-if="jobDetails.showApplyButton" class="content-box text-center">
              <h2 class="primary--text">{{ $t('jobs.applyPromptTitle') }}</h2>
              <p>
                {{ $t('jobs.applyPromptInfo') }}
              </p>
              <div class="mt-6">
                <job-buttons :joboffer="joboffer"></job-buttons>
              </div>
            </div>
          </div>
        </div>
      </v-col>

      <v-col cols="12" lg="4" class="job-details__columns__right-col">
        <div class="job-details__columns__right-col__content">
          <basics
            v-if="$vuetify.breakpoint.lgAndUp"
            :joboffer="joboffer"
            :job-details="jobDetails"
            :block-buttons="
              $vuetify.breakpoint.smAndDown || $vuetify.breakpoint.lgAndUp
            "
          ></basics>

          <div
            class="content-box alternate job-details__contact-box"
            :class="{ 'content-box--has-top-image': jobDetails.contactPhoto }"
          >
            <div v-if="jobDetails.showContactPhoto && jobDetails.contactPhoto" class="content-box__top-image">
              <v-img
                class="round-image"
                :src="`${baseUrl}${jobDetails.contactPhoto}`"
              ></v-img>
            </div>
            <v-row class="content-box__content">
              <v-col cols="12">
                <p>
                  <strong>{{ jobDetails.contactName }}</strong>
                </p>
                <p>{{ localize(jobDetails.contactInfoText) }}</p>

                <div>
                  <v-btn
                    v-if="jobDetails.showContactPhone"
                    small
                    class="mr-12 mb-2 mb-sm-4"
                    :href="`tel:${jobDetails.contactPhone}`"
                  >
                    {{ jobDetails.contactPhone }}
                    <v-icon class="append-icon">mdi-phone</v-icon>
                  </v-btn>

                  <div v-if="jobDetails.showContactEmail">
                    <v-btn small :href="`mailto:${jobDetails.contactEmail}`">
                      Mail
                      <v-icon class="append-icon">mdi-email-outline</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>

          <div
            class="content-box content-box__dense alternate job-details__share-icons"
            v-if="hasSocialMediaLinks"
          >
          <a v-if="jobDetails.showFacebookLink" :href="jobDetails.facebookUrl" target="_blank" aria-label="Facebook Profil">
              <v-icon>$vuetify.icons.facebook</v-icon>
            </a>

            <a v-if="jobDetails.showLinkedInLink" :href="jobDetails.linkedInUrl" target="_blank" aria-label="LinkedIn Profil">
              <v-icon>$vuetify.icons.linkedin</v-icon>
            </a>

            <a v-if="jobDetails.showXingLink" :href="jobDetails.xingUrl" target="_blank" aria-label="Xing Profil">
              <v-icon>$vuetify.icons.xing</v-icon>
            </a>

            <a v-if="jobDetails.showInstagramLink" :href="jobDetails.instagramUrl" target="_blank" aria-label="Instagram Profil">
              <v-icon>$vuetify.icons.instagram</v-icon>
            </a>

            <a v-if="jobDetails.showTwitterLink" :href="jobDetails.twitterUrl" target="_blank" aria-label="X Profil">
              <v-icon>mdi-twitter</v-icon>
            </a>

            <a v-if="jobDetails.showYouTubeLink" :href="jobDetails.youTubeUrl" target="_blank" aria-label="YouTube Profil">
              <v-icon>$vuetify.icons.youtube</v-icon>
            </a>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="mobile-footer-content">
      <job-footer-buttons :joboffer="joboffer" dense></job-footer-buttons>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import basics from '@/components/jobDetails/job-basics-alt.vue';
import contentArea from '@/components/content/content-area.vue';
import jobFooterButtons from '@/components/jobDetails/job-footer-buttons.vue';
import jobButtons from '@/components/jobDetails/job-buttons.vue';
import headerImages from '@/components/jobDetails/job-header-images.vue';

export default {
  props: {
    apiUrl: {
      type: String,
      required: true
    },

    joboffer: {
      type: Object,
      required: true
    },

    jobDetails: {
      type: Object,
      required: true
    },

    slug: {
      type: String,
      required: true
    }
  },

  data: () => ({
    showBottomNav: true
  }),

  components: {
    basics,
    contentArea,
    headerImages,
    jobFooterButtons,
    jobButtons
  },

  computed: {
    ...mapState('companyModule', {
      baseUrl: 'baseUrl'
    }),

    absoluteOverlay() {
      return true;
    },

    hasSocialMediaLinks() {
      return this.jobDetails.showFacebookLink ||
        this.jobDetails.showLinkedInLink ||
        this.jobDetails.showXingLink ||
        this.jobDetails.showInstagramLink ||
        this.jobDetails.showTwitterLink ||
        this.jobDetails.showYouTubeLink;
    }
  },

  created() {
    this.$route.meta.hasScrollMenu = false;
  }
};
</script>
