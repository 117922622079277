import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework'
import de from 'vuetify/lib/locale/de';
import customIcons from './custom-icons.js';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    values: { ...customIcons }
  },

  lang: {
    locales: { de },
    current: 'de'
  }
});
