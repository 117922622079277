export default {
  getDetails(slug, jobofferId) {
    return fetch(
      `${process.env.VUE_APP_API_URL}/job/details?slug=${slug}&jobofferId=${jobofferId}`
    );
  },

  getJoboffers(slug) {
    return fetch(`${process.env.VUE_APP_API_URL}/company/listjobs/${slug}`);
  },

  getInternalJoboffers(slug, token) {
    return fetch(
      `${process.env.VUE_APP_API_URL}/company/listinternaljobs?slug=${slug}&internalToken=${token}`
    );
  }
};
