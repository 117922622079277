export default {
  confirm(jobalertId, jobalertToken) {
    return fetch(`${process.env.VUE_APP_API_URL}/jobalert/confirm`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        jobalertId,
        jobalertToken
      })
    });
  },

  signup(jobalertData) {
    return fetch(`${process.env.VUE_APP_API_URL}/jobalert/signup`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(jobalertData)
    });
  },

  unsubscribe(jobalertId, jobalertToken) {
    return fetch(`${process.env.VUE_APP_API_URL}/jobalert/unsubscribe`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        jobalertId,
        jobalertToken
      })
    });
  }
};
