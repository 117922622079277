<template>
  <ul class="language-menu" v-if="Object.keys(languages).length > 1">
    <li :class="getMenuClass('de-DE')" @click="setLanguage('de-DE')">
      <button text>DE</button>
    </li>
    <li
      :class="getMenuClass('en-US')"
      @click="setLanguage('en-US')"
      v-if="languages['en']"
    >
      <button text>EN</button>
    </li>
  </ul>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({
    currentLanguageKey: 'de-DE',
    activeClass: 'language-menu__active',
    inactiveClass: ''
  }),

  computed: {
    ...mapState('companyModule', {
      pageTitle: 'pageTitle',
      languages: 'languages'
    })
  },

  methods: {
    getMenuClass(langKey) {
      if (this.currentLanguageKey === langKey) {
        return this.activeClass;
      } else {
        return this.inactiveClass;
      }
    },

    setLanguage(langKey) {
      this.currentLanguageKey = langKey;
      this.$store.state.currentLanguage = langKey;
      this.$i18n.locale = langKey.substring(0, langKey.indexOf('-'));

      document.title = this.localize(this.pageTitle);
    }
  }
};
</script>
