import companyService from '@/services/CompanyService.js';

export default {
  getCompanySettings() {
    const settings = companyService.getCompanySettings();
    return settings;
  },

  async loadDepartments({ state }, slug) {
    if (state.departments && state.departments.length > 0) {
      return state.departments;
    }

    const departmentsResponse = await companyService.getDepartments(slug);
    const departments = await departmentsResponse.json();

    state.departments = departments;
    return departments;
  },

  async loadLocale(context, slug) {
    const localeResponse = await companyService.getLocaleSettings(slug);
    const locale = await localeResponse.json();

    return locale;
  },

  async loadLocations({ state }, slug) {
    if (state.locations && state.locations.length > 0) {
      return state.locations;
    }

    const locationsResponse = await companyService.getLocations(slug);
    const locations = await locationsResponse.json();

    state.locations = locations;
    return locations;
  },

  async loadImprint({ state }, slug) {
    if (state.imprint && state.imprint.length > 0) {
      return state.imprint;
    }

    const imprintResponse = await companyService.getImprint(slug);
    const imprint = await imprintResponse.json();

    state.imprint = imprint;
    return imprint;
  },

  async loadPrivacyPolicy({ state }, slug) {
    if (state.privacyPolicy && state.privacyPolicy.length > 0) {
      return state.privacyPolicy;
    }

    const privacyPolicyResponse = await companyService.getPrivacyPolicy(slug);
    const privacyPolicy = await privacyPolicyResponse.json();

    state.privacyPolicy = privacyPolicy;
    return privacyPolicy;
  },

  async loadTranslations(context, { slug, translationKey }) {
    const translationsResponse = await companyService.getTranslations(
      slug,
      translationKey
    );
    return await translationsResponse.json();
  },

  async loadSkills(context, { slug }) {
    const skillsResponse = await companyService.getSkills(slug);
    return await skillsResponse.json();
  }
};
