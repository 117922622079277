<template>
  <div class="desktop-filters">
    <v-row>
      <v-col cols="2">
        <v-icon class="mr-2 large" color="primary">mdi-tune</v-icon>
        <span class="text-sm"><strong>Filter</strong></span>
      </v-col>

      <v-col v-if="filters.includes('company-name')" cols="3">
        <v-select
          v-model="selectedCompanies"
          :items="companies"
          :label="$t('jobs.company')"
          class="desktop-filters__v-select"
          item-text="name"
          item-value="id"
          multiple
          chips
          hide-details
          return-object
          offset-y
        >
          <template v-slot:item="{ item, attrs, on }">
            <v-list-item v-on="on" v-bind="attrs">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }} ({{ item.numJobs }})
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
      </v-col>

      <v-col v-if="filters.includes('locations')" cols="3">
        <v-select
          v-model="selectedLocations"
          :items="locations"
          :label="$t('jobs.location')"
          class="desktop-filters__v-select"
          item-text="name"
          item-value="id"
          multiple
          chips
          hide-details
          return-object
          offset-y
        >
          <template v-slot:item="{ item, attrs, on }">
            <v-list-item v-on="on" v-bind="attrs">
              <v-list-item-content>
                <v-list-item-title>
                  {{ localize(item.name) }} ({{ item.numJobs }})
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-slot:selection="{ item }">
            <v-chip>{{ localize(item.name) }}</v-chip>
          </template>
        </v-select>
      </v-col>

      <v-col v-if="filters.includes('departments')" cols="3">
        <v-select
          v-model="selectedDepartments"
          :items="departments"
          :label="$t('jobs.department')"
          class="desktop-filters__v-select"
          item-text="name"
          item-value="id"
          multiple
          chips
          hide-details
          return-object
          offset-y
        >
          <template v-slot:item="{ item, attrs, on }">
            <v-list-item v-on="on" v-bind="attrs">
              <v-list-item-content>
                <v-list-item-title>
                  {{ localize(item.name) }} ({{ item.numJobs }})
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-slot:selection="{ item }">
            <v-chip>{{ localize(item.name) }}</v-chip>
          </template>
        </v-select>
      </v-col>

      <v-col v-if="filters.includes('category')" cols="3">
        <v-select
          v-model="selectedCategories"
          :items="categories"
          :label="$t('jobs.category')"
          class="desktop-filters__v-select"
          item-text="name"
          item-value="id"
          multiple
          chips
          hide-details
          return-object
          offset-y
        >
          <template v-slot:item="{ item, attrs, on }">
            <v-list-item v-on="on" v-bind="attrs">
              <v-list-item-content>
                <v-list-item-title>
                  {{ localize(item.name) }} ({{ item.numJobs }})
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-slot:selection="{ item }">
            <v-chip>{{ localize(item.name) }}</v-chip>
          </template>
        </v-select>
      </v-col>

      <v-col v-if="filters.includes('career-levels')" cols="3">
        <v-select
          v-model="selectedCareerLevels"
          :items="careerLevels"
          :label="$t('jobs.careerLevel')"
          class="desktop-filters__v-select"
          item-text="name"
          item-value="id"
          multiple
          chips
          hide-details
          return-object
          offset-y
        >
          <template v-slot:item="{ item, attrs, on }">
            <v-list-item v-on="on" v-bind="attrs">
              <v-list-item-content>
                <v-list-item-title>
                  {{ localize(item.name) }} ({{ item.numJobs }})
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-slot:selection="{ item }">
            <v-chip>{{ localize(item.name) }}</v-chip>
          </template>
        </v-select>
      </v-col>

      <v-col v-if="filters.includes('working-times')" cols="3">
        <v-select
          v-model="selectedWorkingTimes"
          :items="workingTimes"
          :label="$t('jobs.workingTime')"
          class="desktop-filters__v-select"
          item-text="name"
          item-value="id"
          multiple
          chips
          hide-details
          return-object
          offset-y
        >
          <template v-slot:item="{ item, attrs, on }">
            <v-list-item v-on="on" v-bind="attrs">
              <v-list-item-content>
                <v-list-item-title>
                  {{ localize(item.name) }} ({{ item.numJobs }})
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-slot:selection="{ item }">
            <v-chip>{{ localize(item.name) }}</v-chip>
          </template>
        </v-select>
      </v-col>

      <v-col cols="1" class="ml-auto">
        <v-btn small fab class="v-btn__light-gray-1" @click="clearAllFilters">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { eventBus } from '@/event-bus.js';

export default {
  data() {
    return {
      showDialog: false,
      locationFilters: [],
      departmentFilters: [],
      selectedDepartments: [],
      selectedLocations: [],
      selectedCareerLevels: [],
      selectedWorkingTimes: [],
      selectedCompanies: [],
      selectedCategories: []
    };
  },

  computed: {
    ...mapState('jobsModule', {
      activeFilters: 'filters'
    }),

    ...mapGetters('jobsModule', {
      locations: 'getLocations',
      departments: 'getDepartments',
      careerLevels: 'getCareerLevels',
      workingTimes: 'getWorkingTimes',
      companies: 'getCompanies',
      categories: 'getCategories'
    }),

    ...mapState('companyModule', {
      filters: 'filters'
    }),

    hasActiveFilters() {
      return Object.entries(this.activeFilters).length > 0;
    },

    sortedWorkingTimes() {
      return [...this.workingTimes].sort((a, b) =>
        this.localize(a.name).localeCompare(this.localize(b.name))
      );
    }
  },

  watch: {
    selectedDepartments() {
      this.clearFilters('departmentId');

      if (!this.selectedDepartments || this.selectedDepartments.length === 0) {
        return;
      }

      for (const selectedDepartment of this.selectedDepartments) {
        this.setFilter({
          field: 'departmentId',
          value: selectedDepartment.id,
          name: selectedDepartment.name
        });
      }

      this.navigateToJoboffers();
    },

    selectedLocations() {
      this.clearFilters('locationId');

      if (!this.selectedLocations || this.selectedLocations.length === 0) {
        return;
      }

      for (const selectedLocation of this.selectedLocations) {
        this.setFilter({
          field: 'locationId',
          value: selectedLocation.id,
          name: selectedLocation.name
        });
      }

      this.navigateToJoboffers();
    },

    selectedCareerLevels() {
      this.clearFilters('careerLevelId');

      if (
        !this.selectedCareerLevels ||
        this.selectedCareerLevels.length === 0
      ) {
        return;
      }

      for (const selectedCareerLevel of this.selectedCareerLevels) {
        this.setFilter({
          field: 'careerLevelId',
          value: selectedCareerLevel.id,
          name: selectedCareerLevel.name
        });
      }

      this.navigateToJoboffers();
    },

    selectedCategories() {
      this.clearFilters('categoryId');

      if (!this.selectedCategories || this.selectedCategories.length === 0) {
        return;
      }

      for (const selectedCategory of this.selectedCategories) {
        this.setFilter({
          field: 'categoryId',
          value: selectedCategory.id,
          name: selectedCategory.name
        });
      }

      this.navigateToJoboffers();
    },

    selectedWorkingTimes() {
      this.clearFilters('workingTimeId');

      if (
        !this.selectedWorkingTimes ||
        this.selectedWorkingTimes.length === 0
      ) {
        return;
      }

      for (const selectedWorkingTime of this.selectedWorkingTimes) {
        this.setFilter({
          field: 'workingTimeId',
          value: selectedWorkingTime.id,
          name: selectedWorkingTime.name
        });
      }
    },

    selectedCompanies() {
      this.clearFilters('companyId');

      if (!this.selectedCompanies || this.selectedCompanies.length === 0) {
        return;
      }

      for (const selectedCompany of this.selectedCompanies) {
        this.setFilter({
          field: 'companyId',
          value: selectedCompany.id,
          name: selectedCompany.name
        });
      }
    }
  },

  mounted() {
    eventBus.$on('filtersCleared', this.onFiltersCleared);

    for (const filter in this.activeFilters) {
      for (const item in this.activeFilters[filter]) {
        const filterItem = this.activeFilters[filter][item];
        this.selectedDepartments.push({
          id: filterItem.value,
          name: filterItem.name
        });
      }
    }
  },

  methods: {
    ...mapActions('jobsModule', {
      clearFilters: 'clearFilters',
      setFilter: 'setFilter'
    }),

    clearAllFilters() {
      this.clearFilters();
    },

    onFiltersCleared(field) {
      if (!field) {
        this.selectedLocations = null;
        this.selectedDepartments = null;
        this.selectedCareerLevels = null;
        this.selectedCompanies = null;
        this.selectedWorkingTimes = null;
        this.selectedCategories = null;
      }
    },

    navigateToJoboffers() {
      if (this.$route.name !== 'jobs') {
        this.$router.push({ name: 'jobs' });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-dialog .v-card {
  background-color: #ccc;
}
</style>
