export const cookieMixin = {
  methods: {
    setCookie(cookieName, value, ttlInDays) {
      let expires = '';
      if (ttlInDays && ttlInDays > 0) {
        var date = new Date();
        date.setTime(date.getTime() + ttlInDays * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
      }

      document.cookie = cookieName + '=' + (value || '') + expires + '; path=/';
    },

    getCookie(cookieName) {
      const name = cookieName + '=';
      const ca = document.cookie.split(';');

      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
      }

      return null;
    },

    deleteCookie(cookieName) {
      document.cookie =
        cookieName + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
  }
};
