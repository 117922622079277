<template>
  <v-dialog
    v-model="showDialog"
    ref="dialog"
    id="short-apply-dialog"
    persistent
    transition="slide-x-transition"
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        color="primary"
        v-bind="attrs"
        v-on="on"
        :block="block || (!inlineButton && $vuetify.breakpoint.smAndDown)"
      >
        {{ $t('application.applyNow') }}
      </v-btn>
    </template>

    <v-card
      class="alternate apply-form has--title"
      @dragover.prevent="handleDragOver"
      @dragleave.prevent="handleDragLeave"
    >
      <v-card-title class="d-flex">
        <div class="flex-grow-1">{{ $t('application.applyNow') }}</div>
        <div>
          <v-btn small fab class="secondary" @click="close">
            <v-icon class="accent--text">mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text v-if="joboffer">
        <template v-if="loading">
          <v-skeleton-loader
            type="article, list-item-three-line, actions"
          ></v-skeleton-loader>
        </template>
        <template v-else>
          <h2 class="apply-form__joboffer-name">
            {{ localize(joboffer.name) }}
          </h2>

          <v-alert v-if="showErrorAlert" type="error" dismissible class="mt-4">
            {{ errorAlertMessage }}
          </v-alert>

          <ValidationObserver ref="observer">
            <v-form v-model="isValid" @submit.prevent ref="form">
              <personal-data
                v-model="application"
                :basics-settings="basics"
                :shortForm="true"
              ></personal-data>

              <documents-form
                v-model="application"
                :documents-settings="documentsSettings"
                :joboffer-id="joboffer.jobofferId"
                @beginParsing="parsing = true"
                @endParsing="parsing = false"
                @file-parsed="handleParsingData"
              ></documents-form>

              <v-row>
                <v-col cols="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="privacyPolicyConfirmed"
                    rules="isTrue"
                  >
                    <v-checkbox
                      v-model="application.acceptPrivacyPolicy"
                      :label="`${$t('jobalert.acceptPrivacyPolicy')} *`"
                      :error-messages="errors"
                      hide-details="auto"
                    />
                  </ValidationProvider>

                  <div class="text--disabled text-sm">
                    <i18n path="jobalert.viewPrivacyPolicy">
                      <router-link
                        v-if="!customDataPrivacyUrl"
                        :to="{ name: 'privacyPolicy' }"
                        >{{ $t('jobalert.viewPrivacyPolicy_linkLabel') }}</router-link
                      >

                      <a v-else :href="customDataPrivacyUrl" target="_blank">{{
                        $t('jobalert.viewPrivacyPolicy_linkLabel')
                      }}</a>
                    </i18n>
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-btn
                    type="submit"
                    class="secondary"
                    :block="$vuetify.breakpoint.smAndDown"
                    @click.prevent="close"
                  >
                    {{ $t('jobalert.cancel') }}
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6" class="text-right">
                  <v-btn
                    type="submit"
                    :block="$vuetify.breakpoint.smAndDown"
                    :loading="submitting"
                    @click.prevent="submitForm"
                  >
                    {{ $t('application.apply') }}
                    <v-icon class="ml-2">mdi-send</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </template>
      </v-card-text>
    </v-card>

    <cv-parser-dialog v-model="parsing"></cv-parser-dialog>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { eventBus } from '@/event-bus.js';
import { v4 as uuid } from 'uuid';

import personalData from './personal-data.vue';
import documentsForm from './documents-short-form.vue';
import cvParserDialog from './cv-parser-dialog.vue';

export default {
  props: {
    joboffer: {
      type: Object,
      required: false,
      default: () => {}
    },

    inlineButton: {
      type: Boolean,
      required: false,
      default: false
    },

    block: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    showDialog: false,
    loading: true,
    parsing: false,
    application: {},
    isValid: false,
    submitting: false,
    basics: [],
    documentsSettings: null,
    questions: null,
    showErrorAlert: false,
    alertErrorMessage: ''
  }),

  computed: {
    ...mapState('companyModule', {
      customDataPrivacyUrl: 'customDataPrivacyUrl'
    })
  },

  watch: {
    async showDialog() {
      if (this.showDialog) {
        await this.initializeData();
      }
    }
  },

  mounted() {
    if (!this.formId || this.formId === '') {
      this.setFormId(uuid());
    }
  },

  methods: {
    ...mapActions('applicationModule', {
      loadForm: 'loadForm',
      submitApplication: 'submitApplication'
    }),

    ...mapMutations('applicationModule', {
      setFormId: 'setFormId'
    }),

    close() {
      this.showDialog = false;

      if (window.location.href.search(/apply=true/i) >= 0) {
        this.$router.push({ name: 'jobDetails', params: { jobId: this.joboffer.jobofferId }})

      }
    },

    handleDragOver() {
      eventBus.$emit('dragover');
    },

    handleDragLeave() {
      eventBus.$emit('dragleave');
    },

    handleParsingData(data) {
      if (data) {
        this.$set(this.application, 'firstName', data.cvData.firstName);
        this.$set(this.application, 'lastName', data.cvData.lastName);
        this.$set(this.application, 'email', data.cvData.emailAddress);
        this.$set(this.application, 'salutationId', data.cvData.salutation);

        for (const item of data.applicationBasics) {
          if (!item.value || item.value === '') {
            continue;
          }

          const basicItem = this.basics.find(
            (x) => x.dataDefinitionId === item.dataDefinitionId
          );

          if (!basicItem) {
            this.basics.push(item);
          } else if (!basicItem.value || basicItem.value === '') {
            basicItem.value = item.value;
          }
        }

        if (data.userPhoto) {
          this.$set(this.application, 'photo', data.userPhoto);
        }
      }
    },

    async initializeData() {
      this.loading = true;

      const jobId = this.joboffer.jobofferId;
      const formData = await this.loadForm(jobId);
      this.basics = formData.applicationBasicsSettings;

      this.documentsSettings = {
        cvValidationMode: formData.cvValidationMode,
        documentsValidationMode: formData.documentsValidationMode,
        letterValidationMode: formData.letterValidationMode,
        photoValidationMode: formData.photoValidationMode
      };

      this.channelSettings = {
        channelValidationMode: formData.channelValidationMode,
        channelCommentValidationMode: formData.channelCommentValidationMode
      };

      if (
        formData.applicationQuestions &&
        formData.applicationQuestions.length > 0
      ) {
        this.questions = formData.applicationQuestions;
      }

      this.loading = false;
    },

    open() {
      this.showDialog = true;
    },

    async submitForm() {
      this.showErrorAlert = false;
      this.errorAlertMessage = '';

      const result = await this.$refs.observer.validate();
      if (result) {
        this.submitting = true;
        this.application.jobofferId = this.joboffer.jobofferId;

        try {
          this.application.basics = this.basics;
          this.application.questions = this.questions;
          this.application.slug = 'hidden-professionals';
          this.application.disableCvParsing = false;

          const response = await this.submitApplication(this.application);
          const result = await response.json();

          if (response.ok && result.success) {
            eventBus.$emit(
              'successMessage',
              this.$t('application.applicationSubmitted')
            );

            this.close();
          } else {
            eventBus.$emit(
              'errorMessage',
              this.$t(`application.errors.${result.errorCode}`)
            );

            this.showErrorAlert = true;
            this.errorAlertMessage = this.$t(`application.errors.${result.errorCode}`);

            console.log(document.querySelector('.v-dialog'));
            document.querySelector('.v-dialog').scrollTop = 0;
          }
        } catch (error) {
          eventBus.$emit(
            'errorMessage',
            'Es ist ein Fehler beim Speichern der Bewerbung aufgetreten'
          );
        }

        this.submitting = false;
      }
    }
  },

  components: {
    personalData,
    documentsForm,
    cvParserDialog
  }
};
</script>

<style scoped>
.apply-dialog__parsing-layer {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 200px;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
}
</style>
