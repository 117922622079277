<template>
  <v-dialog
    v-model="showDialog"
    persistent
    transition="slide-x-transition"
    max-width="1024px"
  >
    <v-card class="alternate" :class="{ 'has--title': localize(siteName) }">
      <v-card-title class="d-flex">
        <div v-if="localize(siteName)" class="flex-grow-1">
          {{ localize(siteName) }}
        </div>
        <v-spacer></v-spacer>
        <v-btn small fab class="secondary" @click="close">
          <v-icon class="accent--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <content-area v-if="siteContent" :content="siteContent"></content-area>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { eventBus } from '@/event-bus.js';
import { mapActions, mapState } from 'vuex';

import contentArea from '@/components/content/content-area.vue';

export default {
  data: () => ({
    showDialog: false,
    siteContent: null,
    siteName: [],
    siteId: ''
  }),

  computed: {
    ...mapState('companyModule', {
      slug: 'slug'
    })
  },

  mounted() {
    eventBus.$on('show-content-dialog', async (siteName) => {
      this.siteContent = null;
      this.siteId = '';
      this.siteName = [];

      this.showDialog = true;

      const companySlug = this.slug ?? this.$route.params.slug;

      const request = {
        slug: companySlug,
        siteName: siteName
      };

      const siteResponse = await this.getSite(request);
      const siteData = await siteResponse.json();

      this.siteName = siteData.siteName;
      this.siteContent = siteData.content;
      this.siteId = siteData.siteId;
    });
  },

  methods: {
    ...mapActions('sitesModule', {
      getSite: 'getSite'
    }),

    close() {
      this.showDialog = false;

      this.siteContent = null;
      this.siteId = '';
      this.siteName = [];
    }
  },

  components: {
    contentArea
  }
};
</script>
