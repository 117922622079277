<template>
  <div
    v-if="joboffer"
    class="job-details-scroll-menu d-block d-md-flex justify-space-between align-center px-4 px-md-0"
  >
    <div class="mb-2 mb-md-0">
      <v-icon class="mr-2 v-icon__color-light-gray-2">mdi-map-marker</v-icon>
      {{ localize(joboffer.location) }}
    </div>
    <div class="mb-2 mb-md-0">
      <v-icon class="mr-2 v-icon__color-light-gray-2">mdi-information</v-icon>
      {{ localize(joboffer.workingTime) }} / {{ joboffer.hoursPerWeek }} Stunden
    </div>
    <div class="mb-2 mb-md-0">
      <v-icon class="mr-2 v-icon__color-light-gray-2"
        >mdi-calendar-range</v-icon
      >
      {{ startDate }}
    </div>
    <div class="mb-2 mb-md-0">
      <job-buttons :joboffer="joboffer" dense></job-buttons>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import jobButtons from '@/components/jobDetails/job-buttons.vue';

export default {
  computed: {
    ...mapState('jobsModule', {
      joboffer: 'currentJoboffer'
    }),

    startDate() {
      if (!this.joboffer) {
        return '';
      }

      const startDate = new Date(this.joboffer.employmentStartDate);
      if (startDate < new Date()) {
        return 'Ab sofort';
      } else {
        return 'Ab ' + this.$d(startDate);
      }
    }
  },

  components: {
    jobButtons
  }
};
</script>
