import applicationService from '@/services/ApplicationService.js';

export default {
  async getApplicationFile(context, { objectName, contentType }) {
    return await applicationService.GetFile(objectName, contentType);
  },

  async loadForm(context, jobofferId) {
    const response = await applicationService.LoadForm(jobofferId);
    return await response.json();
  },

  async parseCvFile(context, { application, objectId, formId, jobofferId }) {
    return await applicationService.ParseCv(
      application,
      objectId,
      formId,
      jobofferId
    );
  },

  async submitApplication(context, data) {
    return await applicationService.SubmitApplication(data);
  },

  async uploadFile(context, fileData) {
    return await applicationService.UploadFile(
      fileData.file,
      fileData.fileType
    );
  }
};
