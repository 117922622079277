<template>
  <div
    v-if="startDate !== ''"
    class="joboffer-box__job-data mr-sm-8 mb-2 mb-sm-0 d-flex align-center"
  >
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <v-icon class="d-inline">mdi-calendar</v-icon>
          <div class="d-inline-block">
            {{ startDate }}
          </div>
        </div>
      </template>

      <span>{{ $t('jobs.employmentDate') }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    joboffer: {
      type: Object,
      require: true
    }
  },

  computed: {
    startDate() {
      const dateTimeParts = this.joboffer.employmentStartDate.split('T');
      const dateItems = dateTimeParts[0].split('-');

      if (dateItems.length !== 3) {
        return '';
      }

      const date = new Date(dateItems[0], dateItems[1] - 1, dateItems[2]);

      if (date < new Date()) {
        return this.$t('jobs.employmentDate_immediately');
      } else {
        return this.$d(date, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        });
      }
    }
  }
};
</script>
