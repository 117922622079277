<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="`field_${_uid}`"
    :rules="rules"
  >
    <v-select
      v-if="!isSearchable"
      v-model="fieldValue"
      clearable
      outlined
      flat
      hide-details="auto"
      item-text="label"
      item-value="value"
      :label="fieldLabel"
      :items="items"
      :error-messages="errors"
    ></v-select>

    <v-autocomplete
      v-else
      v-model="fieldValue"
      clearable
      outlined
      flat
      hide-details="auto"
      item-text="label"
      item-value="value"
      :label="fieldLabel"
      :items="items"
      :error-messages="errors"
    ></v-autocomplete>
  </ValidationProvider>
</template>

<script>
import { extend, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field can not be empty'
});

export default {
  props: {
    item: {
      type: Object,
      required: true
    },

    value: {
      type: [String, Number, Object],
      required: false,
      default: ''
    }
  },

  computed: {
    fieldLabel() {
      let label = this.localize(this.item.label);

      // TODO: use enumeration
      if (this.item.fieldValidationMode === 2) {
        label += '*';
      }

      return label;
    },

    fieldValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },

    isSearchable() {
      return this.item.options.length > 15;
    },

    items() {
      const vm = this;
      return this.item.options
        .map((x) => {
          return {
            label: vm.localize(x.label),
            value: x.value
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
    },

    rules() {
      if (this.item.fieldValidationMode === 2) {
        return 'required';
      }

      return '';
    }
  },

  components: {
    ValidationProvider
  }
};
</script>
