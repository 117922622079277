<template>
  <div>
    <hero-element :heroClaim="heroClaim" :hasSearchField="true"></hero-element>

    <v-main class="content-area">
      <v-container>
        <v-row>
          <v-col cols="3" class="d-none d-md-block">
            <sidebar-filter></sidebar-filter>
          </v-col>
          <v-col cols="12" md="9">
            <router-view v-slot="{ Component }">
              <transition name="fade">
                <component :is="Component" />
              </transition>
            </router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import sidebarFilter from '@/components/jobs/sidebar-filter.vue';
import heroElement from '@/components/hero-element.vue';

export default {
  props: {
    heroClaim: {
      type: String,
      required: true
    }
  },

  components: {
    sidebarFilter,
    heroElement
  }
};
</script>
