<template>
  <div class="content-box company-benefits-list">
    <h2 v-if="hasTitle">{{ title }}</h2>
    <p>
      {{ localize(this.data.text) }}
    </p>

    <swiper-container init="false">
      <swiper-slide>
        <img
          :src="`${apiUrl}/file/public?slug=demo&fileName=teambuilding-1.jpg`"
        />
      </swiper-slide>
      <swiper-slide>
        <img
          :src="`${apiUrl}/file/public?slug=demo&fileName=teambuilding-2.jpg`"
        />
      </swiper-slide>
      <swiper-slide>
        <img
          :src="`${apiUrl}/file/public?slug=demo&fileName=teambuilding-3.jpg`"
        />
      </swiper-slide>

      <swiper-slide>
        <img
          :src="`${apiUrl}/file/public?slug=demo&fileName=teambuilding-4.jpg`"
        />
      </swiper-slide>

      <swiper-slide>
        <img
          :src="`${apiUrl}/file/public?slug=demo&fileName=teambuilding-5.jpg`"
        />
      </swiper-slide>

      <div class="swiper-pagination"></div>

      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </swiper-container>
  </div>
</template>

<script>
import { register } from 'swiper/element/bundle';

import '@/assets/swiper-carousel/effect-carousel.scss';

// eslint-disable-next-line
import EffectCarousel from '@/assets/swiper-carousel/effect-carousel.esm';

register();

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    apiUrl: process.env.VUE_APP_API_URL
  }),

  computed: {
    hasTitle() {
      return (
        this.data.title &&
        Object.keys(this.data.title).length > 0 &&
        this.localize(this.data.title) !== ''
      );
    },

    title() {
      if (Array.isArray(this.data.title)) {
        return this.localize(this.data.title);
      } else {
        return this.localize(this.data.title[0]);
      }
    }
  },

  mounted() {
    const swiperEl = document.querySelector('swiper-container');

    const params = {
      modules: [EffectCarousel],
      effect: 'carousel',
      pagination: true,
      navigation: true,
      slidesPerView: 1,
      centeredSlides: true,
      loop: true,

      breakpoints: {
        640: {
          slidesPerView: 2
        }
      }
    };

    Object.assign(swiperEl, params);

    swiperEl.initialize();

    let maxHeight = 0;
    const cards = document.querySelectorAll('.carousel-card');
    for (const card of cards) {
      const height = card.offsetHeight;
      maxHeight = Math.max(height, maxHeight);
    }

    for (const card of cards) {
      card.style.height = `${maxHeight}px`;
    }

    document.querySelector('swiper-container').style.height = `${
      maxHeight + 120
    }px`;
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .custom-icon {
    height: 200px;
    width: 200px;
    fill: var(--primary) !important;
  }
}

swiper-container {
  height: 550px !important;
}

img {
  // width: 100%;
  width: 500px;
  height: 500px;
}

.swiper-wrapper {
  padding-left: 150px;
}
</style>
