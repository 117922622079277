export default Object.freeze({
  ApplicationFieldTypes: {
    Text: 1,
    Numeric: 2,
    Date: 3,
    Range: 4,
    Checkbox: 5,
    Select: 6,
    Email: 7,
    DateDropdown: 8,
    Salary: 9,
    Textarea: 10
  }
});
