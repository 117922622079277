<template>
  <div></div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { eventBus } from '@/event-bus.js';

export default {
  computed: {
    ...mapState('companyModule', {
      slug: 'slug'
    })
  },

  async mounted() {
    const jobalertId = this.$route.params.jobalertId;
    const jobalertToken = this.$route.params.jobalertToken;

    let success = false;
    let message = '';

    if (this.$route.name === 'confirmJobalert') {
      const response = await this.confirmJobalert({
        jobalertId,
        jobalertToken
      });

      const result = await response.json();
      success = result.success;

      message = 'Die Jobalert-Anmeldung wurde erfolgreich bestätigt.';
    } else if (this.$route.name === 'unsubscribeJobalert') {
      const response = await this.unsubscribeJobalert({
        jobalertId,
        jobalertToken
      });

      const result = await response.json();
      success = result.success;

      message = 'Sie wurden vom Jobalert abgemeldet.';
    }

    this.$router.push({ name: 'jobs', params: { slug: this.slug } });

    if (success) {
      eventBus.$emit('successMessage', message);
    } else {
      eventBus.$emit(
        'errorMessage',
        'Es ist ein Fehler aufgetreten. Die Aktion konnte nicht durchgeführt werden.'
      );
    }
  },

  methods: {
    ...mapActions('jobsModule', {
      confirmJobalert: 'confirmJobalert',
      unsubscribeJobalert: 'unsubscribeJobalert'
    })
  }
};
</script>
