<template>
  <div>
    <v-btn small class="secondary" @click="toggleContent">
      {{ title }}
      <v-icon class="ml-2">{{ iconClass }}</v-icon>
    </v-btn>
    <div class="mt-4" v-show="expanded">
      <div v-html="localize(text)"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    expanded: false
  }),

  computed: {
    ...mapState('companyModule', {
      slug: 'slug'
    }),

    colorClass() {
      if (this.data.variant === 1) {
        return 'content-box__color-primary';
      } else if (this.data.variant === 2) {
        return 'content-box__color-secondary';
      } else {
        return '';
      }
    },

    hasImageClass() {
      if (this.data.variant > 0) {
        return 'content-box__has-image';
      } else {
        return '';
      }
    },

    hasCenteredClass() {
      if (this.data.isCentered) {
        return 'text-center';
      } else {
        return '';
      }
    },

    hasDenseClass() {
      if (this.data.isDense) {
        return 'content-box__dense';
      } else {
        return '';
      }
    },

    hasNoMarginClass() {
      if (this.data.hasNoMargin) {
        return 'content-box__no-margin';
      } else {
        return '';
      }
    },

    iconClass() {
      return this.expanded ? 'mdi-chevron-up' : 'mdi-chevron-down';
    },

    text() {
      if (!this.data.text || this.data.text.length === 0) {
        return '';
      }

      return this.localize(this.data.text);
    },

    title() {
      if (!this.data.title || this.data.title.length === 0) {
        return '';
      }

      return this.localize(this.data.title);
    }
  },

  methods: {
    toggleContent() {
      this.expanded = !this.expanded;
    }
  }
};
</script>
