<template>
  <div class="content-box company-benefits-list">
    <h2 v-if="hasTitle">{{ title }}</h2>
    <p>
      {{ localize(this.data.text) }}
    </p>

    <swiper-container init="false">
      <swiper-slide>
        <!-- <v-card class="alternate">
          <v-card-text
            class="d-flex align-center justify-end flex-grow-1 flex-column text-center"
            style="height: 100%"
          >
            <v-icon x-large color="primary">mdi-rocket-outline</v-icon>
            <h3>1. Online-Bewerbung</h3>
            <p>
              Wir haben unseren Bewerbungsprozess bewusst unkompliziert
              gestaltet. Über unser Online-Portal können Sie sich schnell und
              einfach bewerben.
            </p>
          </v-card-text>
        </v-card> -->

        <v-card class="alternate carousel-card" elevation="5">
          <v-card-text
            class="d-flex align-center justify-end flex-grow-1 flex-column text-center"
          >
            <v-icon>$vuetify.icons.onlineApplication</v-icon>

            <h3>1. Online-Bewerbung</h3>
            <p>
              Wir haben unseren Bewerbungsprozess bewusst unkompliziert
              gestaltet. Über unser Online-Portal kannst du dich schnell und
              einfach bewerben. Ein aussagekräftiger Lebenslauf und ein
              persönliches Anschreiben, in dem du deine Leidenschaften und
              Motivationen mit uns teilst, sind der erste Schritt, um uns von
              dir zu überzeugen.
            </p>
          </v-card-text>
        </v-card>

        <!-- <img
          src="https://carousel-slider.uiinitiative.com/images/guardians-of-the-galaxy.jpg"
        /> -->
      </swiper-slide>
      <swiper-slide>
        <!-- <v-card class="alternate">
          <v-card-text
            class="d-flex align-center justify-end flex-grow-1 flex-column text-center"
            style="height: 100%"
          >
            <v-icon x-large color="primary">mdi-star</v-icon>
            <h3>2. Bewertung der Bewerbungen</h3>
            <p>
              Nach dem Eingang Ihrer Bewerbung wird die Fachabteilung Ihre
              Unterlagen sorgfältig prüfen und bewerten. Wir achten dabei
              besonders auf Ihre fachlichen Qualifikationen, Ihre Erfahrungen
              und Ihre Eignung für die ausgeschriebene Position.
            </p>
          </v-card-text>
        </v-card> -->

        <v-card class="alternate carousel-card" elevation="5">
          <v-card-text
            class="d-flex align-center justify-end flex-grow-1 flex-column text-center"
          >
            <v-icon>$vuetify.icons.judgement</v-icon>

            <h3>2. Bewertung der Bewerbungen</h3>
            <p>
              Wir, das Team hinter den Kulissen, nehmen uns die Zeit, jede
              Bewerbung sorgfältig zu prüfen. Uns liegt nicht nur an deinen
              fachlichen Qualifikationen, sondern auch an dem, was du als Mensch
              in unser Team einbringen kannst.
            </p>
          </v-card-text>
        </v-card>

        <!-- <img
          src="https://carousel-slider.uiinitiative.com/images/thor-ragnarok.jpg"
        /> -->
      </swiper-slide>
      <swiper-slide>
        <!-- <v-card class="alternate">
          <v-card-text
            class="d-flex align-center justify-end flex-grow-1 flex-column text-center"
            style="height: 100%"
          >
            <v-icon x-large color="primary">mdi-video-account</v-icon>
            <h3>3. Virtuelles Vorstellungsgespräch</h3>
            <p>
              Wenn Ihre Bewerbung unser Interesse geweckt hat, laden wir Sie zu
              einem virtuellen Vorstellungsgespräch via MS-Teams ein. In diesem
              Gespräch haben Sie die Möglichkeit, uns besser kennenzulernen und
              mehr über die ausgeschriebene Position zu erfahren.
            </p>
          </v-card-text>
        </v-card> -->

        <!-- <img
          src="https://carousel-slider.uiinitiative.com/images/justice-league.jpg"
        /> -->

        <v-card class="alternate carousel-card" elevation="5">
          <v-card-text
            class="d-flex align-center justify-end flex-grow-1 flex-column text-center"
          >
            <v-icon>$vuetify.icons.videoInterview</v-icon>

            <h3>3. Virtuelles Vorstellungsgespräch</h3>
            <p>
              Fühlen wir eine Verbindung, laden wir dich zu einem Gespräch über
              MS-Teams ein. Hier hast du die Chance, mehr über uns zu erfahren
              und uns deine Fragen zu stellen.
            </p>
          </v-card-text>
        </v-card>
      </swiper-slide>

      <swiper-slide>
        <!-- <img
          src="https://carousel-slider.uiinitiative.com/images/suicide-squad.jpg"
        /> -->

        <v-card class="alternate carousel-card" elevation="5">
          <v-card-text
            class="d-flex align-center justify-end flex-grow-1 flex-column text-center"
          >
            <v-icon>$vuetify.icons.interview</v-icon>

            <h3>4. Persönliches Gespräch</h3>
            <p>
              Sind wir beide von einer gemeinsamen Zukunft überzeugt, möchten
              wir dich gerne persönlich in unseren Räumen willkommen heißen.
              Dies gibt uns die Gelegenheit, tiefergehende Gespräche zu führen
              und dir deinen zukünftigen Arbeitsplatz zu zeigen.
            </p>
          </v-card-text>
        </v-card>
      </swiper-slide>

      <swiper-slide>
        <v-card class="alternate carousel-card" elevation="5">
          <v-card-text
            class="d-flex align-center justify-end flex-grow-1 flex-column text-center"
          >
            <v-icon>$vuetify.icons.contractOffer</v-icon>
            <h3>5. Vertragsangebot</h3>
            <p>
              Überzeugst du uns, dann erhältst du von uns ein Angebot, das nicht
              nur Ihre Rolle und Vergütung umfasst, sondern auch die vielen
              kleinen Dinge, die das Arbeiten bei uns so besonders machen.
            </p>
          </v-card-text>
        </v-card>
      </swiper-slide>

      <div class="swiper-pagination"></div>

      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </swiper-container>
  </div>
</template>

<script>
import { register } from 'swiper/element/bundle';

import '@/assets/swiper-carousel/effect-carousel.scss';

// eslint-disable-next-line
import EffectCarousel from '@/assets/swiper-carousel/effect-carousel.esm';

register();

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    hasTitle() {
      return (
        this.data.title &&
        Object.keys(this.data.title).length > 0 &&
        this.localize(this.data.title) !== ''
      );
    },

    title() {
      if (Array.isArray(this.data.title)) {
        return this.localize(this.data.title);
      } else {
        return this.localize(this.data.title[0]);
      }
    }
  },

  mounted() {
    const swiperEl = document.querySelector('swiper-container');

    const params = {
      modules: [EffectCarousel],
      effect: 'carousel',
      pagination: true,
      navigation: true,
      slidesPerView: 1,
      centeredSlides: true,
      loop: true,

      breakpoints: {
        640: {
          slidesPerView: 2
        }
      }
    };

    Object.assign(swiperEl, params);

    swiperEl.initialize();

    let maxHeight = 0;
    const cards = document.querySelectorAll('.carousel-card');
    for (const card of cards) {
      const height = card.offsetHeight;
      maxHeight = Math.max(height, maxHeight);
    }

    for (const card of cards) {
      card.style.height = `${maxHeight}px`;
    }

    document.querySelector('swiper-container').style.height = `${
      maxHeight + 120
    }px`;
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .custom-icon {
    height: 200px;
    width: 200px;
    fill: var(--primary) !important;
  }
}
</style>
