<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="`field_${_uid}`"
    :rules="rules"
  >
    <v-text-field
      v-model.number="fieldValue"
      type="number"
      outlined
      flat
      hide-details="auto"
      :label="fieldLabel"
      :error-messages="errors"
    ></v-text-field>
  </ValidationProvider>
</template>

<script>
import { extend, ValidationProvider } from 'vee-validate';
import { required, numeric } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field can not be empty'
});

extend('numeric', {
  ...numeric,
  message: 'This field must be a number'
});

export default {
  props: {
    item: {
      type: Object,
      required: true
    },

    value: {
      type: [String, Number],
      required: false,
      default: ''
    }
  },

  computed: {
    fieldLabel() {
      let label = this.localize(this.item.label);

      // TODO: use enumeration
      if (this.item.fieldValidationMode === 2) {
        label += '*';
      }

      return label;
    },

    fieldValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },

    rules() {
      if (this.item.fieldValidationMode === 2) {
        return 'required|numeric';
      }

      return '';
    }
  },

  components: {
    ValidationProvider
  }
};
</script>
