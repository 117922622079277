<template>
  <div
    v-if="localize(joboffer.extent) !== ''"
    class="joboffer-box__job-data mr-sm-8 mb-2 mb-sm-0 d-flex align-center"
  >
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <v-icon class="d-inline">mdi-clock</v-icon>
          <div class="d-inline-block">
            {{ localize(joboffer.extent) }}
          </div>
        </div>
      </template>

      <span>{{ $t('jobs.extent') }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    joboffer: {
      type: Object,
      require: true
    }
  }
};
</script>
