<template>
  <div>
    <hero-element
      :heroClaim="heroClaim"
      :hasSearchField="homeSearchField"
      :hasJoboffersButton="homeJobsButton"
      :hasMobileFilter="false"
      :slug="slug"
    ></hero-element>

    <v-main class="content-area">
      <v-container>
        <v-row>
          <v-col cols="12">
            <router-view v-slot="{ Component }">
              <transition name="fade">
                <component :is="Component" />
              </transition>
            </router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import heroElement from '@/components/hero-element.vue';

import { mapState } from 'vuex';

export default {
  props: {
    heroClaim: {
      type: String,
      required: true
    },

    slug: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapState('companyModule', {
      homeSearchField: 'homeSearchField',
      homeJobsButton: 'homeJobsButton'
    })
  },

  components: {
    heroElement
  }
};
</script>
