export default {
  setContent(state, content) {
    state.content = content;
  },

  setCurrentJob(state, { joboffer, jobDetails }) {
    state.currentJoboffer = joboffer;
    state.currentJobDetails = jobDetails;
  },

  unsetFilters(state) {
    state.filters = {};
  }
};
