<template>
  <div>
    <content-area :content="privacyPolicy"></content-area>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import contentArea from '@/components/content/content-area.vue';

export default {
  data() {
    return {
      privacyPolicy: []
    };
  },

  computed: {
    ...mapState('companyModule', {
      slug: 'slug'
    })
  },

  watch: {
    async slug() {
      this.privacyPolicy = await this.loadPrivacyPolicy(this.slug);
    }
  },

  async mounted() {
    if (this.slug) {
      this.privacyPolicy = await this.loadPrivacyPolicy(this.slug);
    }
  },

  methods: {
    ...mapActions('companyModule', {
      loadPrivacyPolicy: 'loadPrivacyPolicy'
    })
  },

  components: {
    contentArea
  }
};
</script>
