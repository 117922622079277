<template>
  <div
    class="image-box has-link"
    :class="{
      'has-description': hasDescription,
      'has-description-in-image': descriptionInImage
    }"
    @click="$emit('click')"
  >
    <v-img :src="src" class="image-box__image">
      <v-overlay absolute :opacity="opacity">
        <template v-if="descriptionInImage && hasDescription">
          <h3><slot name="description"></slot></h3>
        </template>
      </v-overlay>
    </v-img>
    <div
      v-if="!descriptionInImage && hasDescription"
      class="image-box__description"
    >
      <slot name="description"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true
    },

    opacity: {
      type: Number,
      required: false,
      default: 0
    },

    to: {
      type: Object,
      required: false,
      default: () => null
    },

    descriptionInImage: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  computed: {
    hasDescription() {
      return !!this.$slots.description;
    }
  }
};
</script>

<style lang="scss" scoped>
.has-link:hover {
  cursor: pointer;
}
</style>
