export const ContentEnums = Object.freeze({
  componentTypes: {
    TextBox: 1,
    AdvertTextBox: 2,
    CompanyBenefits: 3,
    HtmlContent: 4,
    ContentGrid: 5,
    CompanyDepartments: 6,
    Expander: 7,
    Accordion: 8,
    Image: 9,
    ImageGrid: 10,
    VideoBox: 11,
    Swiper: 12,
    Testimonials: 13,
    ImageSwiper: 14,
    JobBenefits: 15,
    AdvertVideo: 16
  },

  videoTypes: {
    SelfHosted: 1,
    YouTube: 2,
    Vimeo: 3
  },

  visibility: {
    Always: 0,
    ExternalOnly: 1,
    InternalOnly: 2
  }
});
