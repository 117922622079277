<template>
  <v-app
    v-if="slug && themeLoaded"
    class="default"
    :class="`${layout} ${heroClass}`"
    :style="{
      '--hero-image-url': 'url(' + heroImagePath + ')'
    }"
    @drop.stop.prevent
  >
  <link rel="preload" href="@/assets/fonts/roboto-v29-latin/roboto-v30-latin-regular.woff2" as="font" type="font/woff2" crossorigin>
  <link rel="preload" href="@/assets/fonts/materialdesignicons-webfont.woff2" as="font" type="font/woff2" crossorigin>
    <v-app-bar app>
      <v-container>
        <v-row>
          <v-col cols="5" md="4">
            <router-link v-if="!swapHomePageButtons" :to="homeRoute">
              <img
                class="app-bar__company-logo"
                :src="`${apiUrl}/file/public?slug=${slug}&fileName=${logoName}`"
                alt="company-name"
                title="Logo des Unternehmens"
              />
            </router-link>
            <a v-else :href="homepageUrl" target="_blank">
              <img
                class="app-bar__company-logo"
                :src="`${apiUrl}/file/public?slug=${slug}&fileName=${logoName}`"
                alt="company-name"
                title="Logo des Unternehmens"
              />
            </a>
          </v-col>
          <v-col cols="12" md="4" class="d-none d-md-flex">
            <template v-if="showAppBarSearch">
              <fulltext-search filled></fulltext-search>
            </template>
          </v-col>
          <v-col
            cols="7"
            md="4"
            class="ml-auto d-flex justify-end align-center"
          >
            <template v-if="homepageUrl && !swapHomePageButtons">
              <v-btn
                :href="homepageUrl"
                target="_blank"
                text
                class="ml-auto px-4 px-sm-8 mr-0 mr-sm-4"
              >
                <v-icon class="mr-0 mr-sm-2">mdi-home</v-icon>
                <span class="d-none d-sm-inline">{{
                  localize(homepageLabel)
                }}</span>
              </v-btn>
            </template>
            <template v-else-if="swapHomePageButtons">
              <v-btn
                text
                @click="goTo(homeRoute)"
                class="ml-auto px-4 px-sm-8 mr-0 mr-sm-4"
              >
                <v-icon class="mr-0 mr-sm-2">mdi-home</v-icon>
                <span class="d-none d-sm-inline">{{
                  localize(homepageLabel)
                }}</span>
              </v-btn>
            </template>

            <language-menu></language-menu>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <div
      v-if="this.$route.meta.hasScrollMenu"
      class="d-flex"
      style="justify-content: center"
    >
      <v-container class="fixed-menu d-none" id="fixed-top-menu">
        <v-banner dense elevation="3" class="white">
          <router-view name="scrollMenu"></router-view>
        </v-banner>
      </v-container>
    </div>

    <component :is="layout" :heroClaim="heroClaim" :slug="slug"></component>

    <footer>
      <html-content
        v-if="footerContent?.text && footerContent.text.length > 0"
        :data="footerContent"
      ></html-content>

      <div class="bottom-footer">
        <v-container class="d-block d-md-flex text-center text-md-left">
          <div class="flex-grow-1 mb-8 mb-md-0">
            &copy; {{ currentYear }} - {{ copyrightInfo }}
          </div>
          <div class="ml-auto">
            <router-link v-if="!customImprintUrl" :to="{ name: 'imprint' }">
              <span class="text-sm text-md-reg">{{ $t('imprint') }}</span>
            </router-link>

            <a v-else :href="customImprintUrl" target="_blank">
              <span class="text-sm text-md-reg">{{ $t('imprint') }}</span>
            </a>
            /
            <router-link
              v-if="!customDataPrivacyUrl"
              :to="{ name: 'privacyPolicy' }"
            >
              <span class="text-sm text-md-reg">
                {{ $t('privacyPolicy') }}
              </span>
            </router-link>

            <a v-else :href="customDataPrivacyUrl" target="_blank">
              <span class="text-sm text-md-reg">
                {{ $t('privacyPolicy') }}
              </span>
            </a>
          </div>
        </v-container>
      </div>
    </footer>

    <v-snackbar
      v-model="jobalertSuccess"
      :timeout="snackbarTimeout"
      color="success"
    >
      {{ $t('jobalert.successMessage') }}

      <template v-slot:action="{ attrs }">
        <v-btn fab text v-bind="attrs" @click="jobalertSuccess = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="jobalertError" color="red" :timeout="snackbarTimeout">
      {{ $t('jobalert.errorMessage') }}

      <template v-slot:action="{ attrs }">
        <v-btn fab text v-bind="attrs" @click="jobalertError = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="showSuccessMessage"
      :timeout="snackbarTimeout"
      color="success"
    >
      {{ successMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn fab text v-bind="attrs" @click="showSuccessMessage = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="showErrorMessage"
      color="red"
      :timeout="snackbarTimeout"
    >
      {{ errorMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn fab text v-bind="attrs" @click="showErrorMessage = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <template v-if="useConsentManager">
      <comply-consent-manager
        :apiKey="consentManagerKey"
      ></comply-consent-manager>
    </template>
    <template v-else-if="slug">
      <cookie-banner></cookie-banner>
    </template>

    <content-dialog></content-dialog>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { eventBus } from '@/event-bus.js';

import languageMenu from '@/components/language-menu.vue';
import fulltextSearch from '@/components/fulltext-search.vue';
import cookieBanner from '@/components/cookie-banner.vue';
import htmlContent from '@/components/content/html-content.vue';
import contentDialog from '@/components/content-dialog.vue';

import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

export default {
  name: 'App',

  data: () => ({
    themeLoaded: false,
    slug: null,
    logoName: null,
    apiUrl: process.env.VUE_APP_API_URL,
    webguardKey: process.env.VUE_APP_WEBGUARD_KEY,
    consentManagerKey: process.env.VUE_APP_CONSENT_MANAGER_KEY,
    companyLayout: 'top-filter-layout',
    jobalertError: false,
    jobalertSuccess: false,
    snackbarTimeout: 5000,
    errorMessage: '',
    showErrorMessage: false,
    successMessage: '',
    showSuccessMessage: false,
    homepageUrl: '',
    homepageLabel: [],
    copyrightLabel: [],
    customImprintUrl: '',
    customDataPrivacyUrl: '',
    customCss: ''
  }),

  created() {
    if (this.useConsentManager) {
      let tag = document.createElement('script');
      tag.setAttribute('src', 'https://webguard.cb-sol.de/loader.js');
      tag.setAttribute('apiKey', this.webguardKey);
      document.head.appendChild(tag);

      tag = document.createElement('script');
      tag.setAttribute('src', 'https://ccl-webguard.cb-sol.de/js/app.js');
      document.head.appendChild(tag);
    }
  },

  async mounted() {
    const response = await this.getCompanySettings();
    const settings = await response.json();

    if (settings.pageTitle && settings.pageTitle.length > 0) {
      document.title = this.localize(settings.pageTitle);
    }

    if (settings.defaultClaim && settings.defaultClaim.length > 0) {
      this.setHeroClaim(settings.defaultClaim);
    }

    if (settings.defaultHeroImage) {
      this.setHeroImage(settings.defaultHeroImage);
    }

    this.slug = settings.slug;
    this.setCompanySettings(settings);

    await this.setCompanyLocale(settings.slug);

    this.logoName = settings.companyLogoName;
    this.companyLayout = settings.layout;
    this.homepageUrl = settings.homepageUrl;
    this.homepageLabel = settings.homepageLabel;
    this.copyrightLabel = settings.copyrightLabel;
    this.customImprintUrl = settings.customImprintUrl;
    this.customDataPrivacyUrl = settings.customDataPrivacyUrl;
    this.swapHomePageButtons = settings.swapHomePageButtons;
    this.customCss = settings.customCss;

    this.setRouteImages(settings.routeImages);

    this.loadDepartments(settings.slug);
    this.loadLocations(settings.slug);

    if (
      this.$route.params.internalToken &&
      this.$route.params.internalToken.toLowerCase() ===
        settings.internalToken.toLowerCase()
    ) {
      this.setInternal();

      await this.loadInternalJobs({
        slug: settings.slug,
        internalToken: settings.internalToken
      });
    } else {
      await this.loadJobs(settings.slug);
    }

    this.setFavicon();

    this.setCustomCss(settings.customCss);

    this.themeLoaded = true;

    this.setupEventBus();
    this.setupValidationRules();
  },

  computed: {
    ...mapState({
      heroImageUrl: 'heroImageUrl',
      routeImages: 'routeImages',
      currentLanguage: 'currentLanguage'
    }),

    ...mapState('companyModule', {
      jobportalOnly: 'jobportalOnly',
      footerContent: 'footerContent'
    }),

    ...mapGetters({
      heroClaim: 'heroClaim'
    }),

    copyrightInfo() {
      const localizedLabel = this.localize(this.copyrightLabel);
      if (localizedLabel) {
        return localizedLabel;
      } else {
        return 'hidden professionals GmbH';
      }
    },

    currentYear() {
      return new Date().getFullYear();
    },

    heroClass() {
      let heroClass = '';
      if (this.$route.meta.heroClass) {
        heroClass = this.$route.meta.heroClass;
      }

      return heroClass;
    },

    heroImagePath() {
      let imgUrl = '';

      if (this.heroImageUrl) {
        imgUrl = `${this.apiUrl}/file/public?slug=${this.slug}&fileName=${this.heroImageUrl}`;
      }

      return imgUrl;
    },

    layout() {
      return this.$route.meta.layout || this.companyLayout;
    },

    homeRoute() {
      if (this.jobportalOnly) {
        return { name: 'jobs' };
      } else {
        return `/${this.slug}`;
      }
    },

    showAppBarSearch() {
      return this.layout === 'top-filter-layout';
    },

    useConsentManager() {
      return this.slug && this.slug === 'biotest';
    }
  },

  watch: {
    $route() {
      window.setTimeout(() => {
        const elementTargets =
          document.getElementsByClassName('fixed-menu-anchor');
        if (!elementTargets || elementTargets.length === 0) {
          return;
        }

        const elementTarget = elementTargets[0];

        let observerOptions = {
          rootMargin: '-80px',
          threshold: 0.5
        };

        const observer = new IntersectionObserver(
          this.observerCallback,
          observerOptions
        );
        observer.observe(elementTarget);
      }, 800);
    },

    currentLanguage() {
      this.setupValidationRules();
    }
  },

  methods: {
    ...mapActions('companyModule', {
      getCompanySettings: 'getCompanySettings',
      loadDepartments: 'loadDepartments',
      loadLocations: 'loadLocations',
      loadLocale: 'loadLocale'
    }),

    ...mapActions('jobsModule', {
      loadJobs: 'loadJobs',
      loadInternalJobs: 'loadInternalJobs'
    }),

    ...mapMutations({
      setBenefits: 'companyModule/setBenefits',
      setCompanySettings: 'companyModule/setCompanySettings',
      setCompanySlug: 'companyModule/setCompanySlug',
      setInternal: 'companyModule/setInternal',
      setHeroClaim: 'setHeroClaim',
      setHeroImage: 'setHeroImage',
      setRouteImages: 'setRouteImages'
    }),

    goTo(route) {
      this.$router.push(route);
    },

    isObject(item) {
      return item && typeof item === 'object' && !Array.isArray(item);
    },

    async loadCompanyLocale(slug, messages) {
      const companyMessages = await this.loadLocale(slug);

      this.mergeDeep(messages, companyMessages);

      this.$i18n.setLocaleMessage('de', messages['de']);
      this.$i18n.setLocaleMessage('en', messages['en']);
    },

    mergeDeep(target, ...sources) {
      if (!sources.length) return target;
      const source = sources.shift();

      if (this.isObject(target) && this.isObject(source)) {
        for (const key in source) {
          if (this.isObject(source[key])) {
            if (!target[key]) Object.assign(target, { [key]: {} });
            this.mergeDeep(target[key], source[key]);
          } else {
            Object.assign(target, { [key]: source[key] });
          }
        }
      }

      return this.mergeDeep(target, ...sources);
    },

    observerCallback(entries) {
      entries.forEach((entry) => {
        const menuElement = document.getElementById('fixed-top-menu');
        if (!menuElement) {
          return;
        }

        if (entry.isIntersecting) {
          menuElement.classList.add('d-none');
        } else {
          menuElement.classList.remove('d-none');
        }
      });
    },

    async setCompanyLocale(slug) {
      const locales = require.context(
        './locales',
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
      );

      const messages = {};
      locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
          const locale = matched[1];
          messages[locale] = locales(key);
        }
      });

      await this.loadCompanyLocale(slug, messages);
    },

    setCustomCss(customCss) { 
      if (!customCss) {
        return;
      }

      const style = document.createElement('style');
      style.innerHTML = customCss;
      document.head.appendChild(style);
    },

    setFavicon() {
      const favicon = document.getElementById('favicon');
      favicon.href = `${this.apiUrl}/file/public?slug=${this.slug}&fileName=favicon-32x32.png`;

      const touchIcon = document.getElementById('touchIcon');
      touchIcon.href = `${this.apiUrl}/file/public?slug=${this.slug}&fileName=android-chrome-192x192.png`;
    },

    setupEventBus() {
      eventBus.$on('jobalert-success', () => {
        this.jobalertSuccess = true;
      });

      eventBus.$on('jobalert-error', () => {
        this.jobalertError = true;
      });

      eventBus.$on('successMessage', (message) => {
        this.showSuccessMessage = true;
        this.successMessage = message;
      });

      eventBus.$on('errorMessage', (message) => {
        this.showErrorMessage = true;
        this.errorMessage = message;
      });
    },

    setupValidationRules() {
      extend('required', {
        ...required,
        message: this.$t('application.fieldIsMandatory')
      });

      extend('email', {
        ...email,
        message: 'Please provide a valid email address'
      });

      extend('fileUploaded', {
        validate(value) {
          return value && value.length > 0;
        },

        message: this.$t('application.fileIsMandatory')
      });

      extend('isTrue', {
        validate(value) {
          return value;
        },

        message: this.$t('application.fieldIsMandatory')
      });
    }
  },

  components: {
    cookieBanner,
    languageMenu,
    fulltextSearch,
    htmlContent,
    contentDialog
  }
};

import('@/assets/styles/materialdesignicons.min.css');
import('@/assets/styles/base/base.scss');
</script>

<style lang="scss" scoped>
.hero {
  .v-banner {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .v-banner__wrapper {
      padding: 2rem;
    }
  }
}
</style>
