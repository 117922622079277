<template>
  <div
    class="content-box content-box__color-secondary content-box__dense"
    :class="[margin, padding]"
  >
    <div class="apply-form__section apply-form__personal-data">
      <h3>{{ localize(chapterTitle) }}</h3>

      <template v-for="(question, index) in chapterQuestions">
        <chapter-question
          v-model="chapterQuestions[index]"
          :key="`chapter_question_${chapterIndex}_${index}`"
          :short-apply="shortApply"
        ></chapter-question>
      </template>
    </div>
  </div>
</template>

<script>
import chapterQuestion from './chapter-question.vue';

export default {
  props: {
    value: {
      type: Array,
      required: true
    },

    chapterIndex: {
      type: Number,
      required: true
    },

    chapterTitle: {
      type: Array,
      required: true
    },

    shortApply: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    chapterQuestions() {
      return this.questions.filter((x) => x.chapterIndex === this.chapterIndex);
    },

    margin() {
      if (this.shortApply) {
        return 'ma-0';
      }

      return 'mt-8';
    },

    padding() {
      if (this.shortApply) {
        return 'pa-0';
      }

      return 'pt-16';
    },

    questions: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      }
    }
  },

  components: {
    chapterQuestion
  }
};
</script>
